import { useEffect, useRef, useState } from 'react';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import LoaderButton from 'components/Button/LoaderButton';
import { getBackendUrl } from 'services/get-backend-url';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import SignInBase from '../SignInBase';
import { RegisterImplementationProps } from 'constants/types';
import { getProviderFormLabel } from '../utils';
export const FACTORIAL_HR_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth/factorial_hr`;
export const FACTORIAL_HR_OAUTH_CALLBACK_KEY = 'factorial_hr_oauth_callback';
const BACKEND_ROUTE = getBackendUrl();
interface FactorialHRConfig {
  oauthUrl: string;
  clientId: string;
  scopes: string[];
}
type OAuthState = {
  code: string | null;
  state: string | null;
  error: string | null;
};
const SignInFactorialHROAuth = ({
  client,
  onSubmit,
  setError,
  sessionKey,
  onMockOAuth,
  provider
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [loading, setLoading] = useState(false);
  const [polling, setPolling] = useState(false);
  const pollingTimer = useRef<NodeJS.Timeout>(); // Reference to timer so we can clean it up
  const externalWindowRef = useRef<Window | null>(null);
  const config: FactorialHRConfig = (client as any).factorialHRApiConfig;
  useEffect(() => () => {
    if (pollingTimer.current) clearTimeout(pollingTimer.current);
  }, []);
  const onCodeReceived = async (oAuthState?: OAuthState) => {
    if (oAuthState?.error) {
      setError({
        message: oAuthState?.error,
        status: null
      });
      setLoading(false);
      return;
    }
    if (oAuthState?.code) {
      try {
        await onSubmit({
          providerRedirectUri: FACTORIAL_HR_REDIRECT_URI,
          clientCode: oAuthState?.code
        });
      } finally {
        setLoading(false);
      }
    }
  };
  useQuery({
    queryKey: ['factorial_hr_oauth_state', sessionKey],
    queryFn: () => {
      if (externalWindowRef?.current?.closed) {
        setLoading(false);
        setPolling(false);
        clearTimeout(pollingTimer.current);
      }
      return axios.get<{
        oAuthState?: OAuthState;
      }>(`${BACKEND_ROUTE}/auth/oauth/state/${sessionKey}`).then(async ({
        data
      }) => {
        if (data.oAuthState) {
          setPolling(false);
          clearTimeout(pollingTimer.current);
          await onCodeReceived(data.oAuthState);
        }
        return data;
      });
    },
    enabled: polling,
    // Poll OAuth state every second
    refetchInterval: 1000
  });
  const handleSubmit = async () => {
    setLoading(true);
    const didMockOAuth = await onMockOAuth();
    if (didMockOAuth) {
      setLoading(false);
      return;
    }
    setPolling(true);
    pollingTimer.current = setTimeout(() => {
      setLoading(false);
      setPolling(false);
    }, 300_000); // Stop polling in 5 minutes

    const url = new URL(config.oauthUrl);
    url.searchParams.set('client_id', config.clientId);
    url.searchParams.set('redirect_uri', FACTORIAL_HR_REDIRECT_URI);
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('state', sessionKey);
    url.searchParams.set('resource_owner_type', 'company');
    externalWindowRef.current = window.open(url.toString(), 'popup', 'popup=true');
  };
  const instructions = <p>
      Factorial HR authentication will continue in a new window. Please make
      sure to allow popups and finish authenticating through Factorial HR in the
      new window.
    </p>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} data-sentry-element="SignInBase" data-sentry-component="SignInFactorialHROAuth" data-sentry-source-file="SignInFactorialHROAuth.tsx">
      <div style={{
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
        <LoaderButton isLoading={loading} disabled={loading} onClick={handleSubmit} text="Connect" data-sentry-element="LoaderButton" data-sentry-source-file="SignInFactorialHROAuth.tsx" />
      </div>
    </SignInBase>;
};
export default withAuthorizeContext(SignInFactorialHROAuth);