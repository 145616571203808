import { Response as ClientResponse, ProviderConfig, ImplementationDetail } from '@finch-api/common/dist/internal/connect/validate';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import styled from 'styled-components';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import LoaderButton from 'components/Button/LoaderButton';
import { instructionsForPayrollProviderId } from './instructions';
import { ConnectError, StateRedirect } from '../types';
import { ProviderAppShell } from '../ProviderAppShell';
const ImplementationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const FINCH_DEFAULT_PHONE = '(407) 741-3626';
const ManualInstruction = ({
  client,
  payrollProvider,
  contactEmailInContext,
  companyNameInContext,
  onSubmit,
  onPrevStep,
  handleStateRedirect,
  providerImplementation,
  error,
  setError
}: {
  client: ClientResponse;
  payrollProvider: ProviderConfig;
  contactEmailInContext?: string | undefined;
  companyNameInContext?: string | undefined;
  onSubmit: () => Promise<void>;
  onPrevStep?: () => void;
  handleStateRedirect: (redirect: StateRedirect) => void;
  providerImplementation: ImplementationDetail;
  error: ConnectError | null;
  setError: (error: ConnectError | null) => void;
}) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions = instructionsForPayrollProviderId(payrollProvider.id)({
    accountantEmail: client?.manualConfig?.email || payrollProvider?.defaultAccountantEmail,
    username: client?.manualConfig?.name || client?.name,
    userId: client?.manualConfig?.userId || client?.manualConfig?.name || client?.name,
    ssn: client?.manualConfig?.ssn,
    phoneNumber: client?.manualConfig?.phoneNumber || FINCH_DEFAULT_PHONE
  });
  const isXeroUSProvider = payrollProvider.id === 'xero_us';
  const onRedirect = () => {
    handleStateRedirect({
      next: 'SIGN_IN',
      nextContext: {
        redirect: true,
        payrollProviderId: 'gusto'
      }
    });
  };
  return <ProviderAppShell provider={payrollProvider} providerImplementation={providerImplementation} error={error} setError={setError} headerText={`Connect your ${payrollProvider.displayName} account`} goBack={!(companyNameInContext && contactEmailInContext) && onPrevStep ? onPrevStep : undefined} data-sentry-element="ProviderAppShell" data-sentry-component="ManualInstruction" data-sentry-source-file="ManualInstruction.tsx">
      <ImplementationWrapper data-sentry-element="ImplementationWrapper" data-sentry-source-file="ManualInstruction.tsx">
        <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="ManualInstruction.tsx">
          To connect your {payrollProvider.displayName} account, you must add{' '}
          <b>{client.name}</b> as an admin to your payroll system. The steps are
          below.
        </InstructionHeader>
        <InstructionList instructions={instructions} color={payrollProvider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="ManualInstruction.tsx" />
        <LoaderButton color={payrollProvider.primaryColor} onClick={isXeroUSProvider ? onRedirect : onSubmit} disabled={!isAccepted} text={isXeroUSProvider ? 'Redirect' : 'Complete'} data-sentry-element="LoaderButton" data-sentry-source-file="ManualInstruction.tsx" />
      </ImplementationWrapper>
    </ProviderAppShell>;
};
export default ManualInstruction;