import { useState } from 'react';
import { validate } from 'email-validator';
import { authorize } from 'services/auth';
import { useAuthFailureCount } from 'store/auth-failure-count';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import Container from 'components/Container/Container';
import SignInGuide from 'pages/Authorize/SignIn/SignInGuide';
import RegisterManualAdapter from './RegisterManualAdapter';
import LoadingSpinner from '../LoadingSpinner';
import ManualInstruction from './ManualInstruction';
import { useFlag } from '@unleash/proxy-client-react';
import SignInManualAdapter from './SignInManualAdapter';
import SignInManualInstruction from './SignInManualInstruction';
import { getStatusCode } from '../SignIn/utils';
import { query } from 'utils/params';
import { paramSessionId } from 'constants/params';
import { ProviderAppShell } from '../ProviderAppShell';
import { shouldTriggerAuthFallback } from 'lib/auth-fallback/utils';
import { FeatureFlag } from 'constants/feature-flags';

/**
 * The component that handles assisted benefits flow and acf flow
 */
const ManualSignIn = ({
  client,
  clientName,
  connectionId,
  payrollProvider,
  providersConfig,
  providerImplementation,
  contactEmail: contactEmailInContext,
  companyName: companyNameInContext,
  handleAuthorizeResponse,
  currentStep,
  clientId,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  sandbox,
  prevStep,
  appType,
  error,
  setError,
  handleStateRedirect,
  implementationHasAssistedBenefits
}: AuthorizeContextValue) => {
  const [isDoneSignIn, setIsDoneSignIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contactEmail, setContactEmail] = useState(contactEmailInContext);
  const [companyName, setCompanyName] = useState(companyNameInContext);
  const useNewDesign = useFlag(FeatureFlag.FinchConnectCleanup);
  const shouldSkipDataCollection = Boolean(query(paramSessionId));
  const authFailureCounts = useAuthFailureCount(state => state.authFailureCounts);
  if (!client) return <Container newContainerDesign={useNewDesign}>
        {!client && <LoadingSpinner />}
      </Container>;
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providersConfig) throw new Error('no providers config');
  if (!providerImplementation) throw new Error('no provider implementation');
  if (!products) throw new Error('no products');
  const onNextStep = (fieldToValueMap: Record<string, string>) => {
    const {
      contactEmail,
      companyName
    } = fieldToValueMap;
    if (contactEmail) {
      if (!validate(contactEmail)) {
        setError({
          message: 'The contact email provided is not valid.',
          omitSessionKey: true,
          status: null
        });
        setLoading(false);
        return;
      }
      setContactEmail(contactEmail);
    }
    if (companyName) setCompanyName(companyName);
    setIsDoneSignIn(true);
  };
  const onSubmit = async () => {
    setLoading(true);
    setError(null);
    if ((!contactEmail || !companyName) && !shouldSkipDataCollection) {
      setError({
        message: 'The contact email or company name is not provided.',
        omitSessionKey: true,
        status: null
      });
      setLoading(false);
      return;
    }
    try {
      const authorizeResponse = await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        sessionKey,
        currentBrowserSessionKey,
        state,
        step: currentStep,
        category,
        contactEmail,
        companyName,
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        sandbox,
        isManualAuth: currentStep !== 'ASSISTED_BENEFITS',
        appType,
        acfFallbackEligible: shouldTriggerAuthFallback(client, authFailureCounts, providerImplementation),
        implementationHasAssistedBenefits
      });
      handleAuthorizeResponse(authorizeResponse);
    } catch (err: any) {
      setError({
        message: err.message || 'Unexpected error has occurred. Please try again.',
        status: getStatusCode(err),
        finchCode: err.response?.data?.finch_code
      });
    }
    setLoading(false);
  };
  const onPreviousStep = () => {
    if (shouldSkipDataCollection) {
      prevStep();
    } else {
      setIsDoneSignIn(false);
      setCompanyName(companyNameInContext);
      setContactEmail(contactEmailInContext);
    }
  };

  // if there's contact email and company name in context
  // then we can go to next step
  if (payrollProvider.id === 'xero_us' || isDoneSignIn || shouldSkipDataCollection || contactEmailInContext && companyNameInContext) {
    if (useNewDesign) {
      return <SignInManualInstruction client={client} payrollProvider={payrollProvider} onSubmit={onSubmit} onPrevStep={onPreviousStep} contactEmailInContext={contactEmailInContext} companyNameInContext={companyNameInContext} handleStateRedirect={handleStateRedirect} providerImplementation={providerImplementation} error={error} setError={setError} />;
    }
    return <ManualInstruction client={client} error={error} setError={setError} payrollProvider={payrollProvider} onSubmit={onSubmit} providerImplementation={providerImplementation} onPrevStep={onPreviousStep} contactEmailInContext={contactEmailInContext} companyNameInContext={companyNameInContext} handleStateRedirect={handleStateRedirect} />;
  }
  const adapterProps = {
    onNextStep: onNextStep,
    contactEmailInContext: contactEmailInContext,
    companyNameInContext: companyNameInContext,
    isLoading: loading
  };
  return <>
      <ProviderAppShell providerImplementation={providerImplementation} error={error} setError={setError} provider={payrollProvider} goBack={providersConfig.length > 1 && currentStep !== 'ASSISTED_BENEFITS' ? prevStep : undefined} data-sentry-element="ProviderAppShell" data-sentry-source-file="ManualSignIn.tsx">
        <SignInGuide client={client} products={products} provider={payrollProvider} currentStep={currentStep} enabled={currentStep === 'ASSISTED_BENEFITS'} data-sentry-element="SignInGuide" data-sentry-source-file="ManualSignIn.tsx">
          {useNewDesign ? <SignInManualAdapter {...adapterProps} provider={payrollProvider} /> : <RegisterManualAdapter {...adapterProps} />}
        </SignInGuide>
      </ProviderAppShell>
    </>;
};
export default withAuthorizeContext(ManualSignIn);