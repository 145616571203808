import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import { AppLink } from 'components/Link/AppLink';
import Input from 'components/Input/Input';
import { getProviderFormLabel } from '../utils';
export function SignInAlphaStaffApi({
  isLoading,
  onSubmit,
  provider
}: RegisterImplementationProps) {
  const instructions: JSX.Element = <>
      <p>
        To connect your AlphaStaff account you must enter your 6-digit client
        ID. If you don&apos;t know your client ID, please&nbsp;
        <AppLink variant="underline" href="https://www.alphastaff.com/contact/" target="_blank" rel="noreferrer">
          contact your AlphaStaff representative
        </AppLink>
        .
      </p>
      <p>
        Your account&apos;s connection will not be complete until you respond to
        the access confirmation request from AlphaStaff. You should receive this
        within 7 business days of submitting this form.
      </p>
    </>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} divider onSubmit={onSubmit} fieldNames={['companyId', 'instructionCheck']} render={({
    instructionCheck,
    companyId
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <Input id="companyId" label="Client ID" characterLimit={6} invalid={companyId.invalid} value={companyId.value} onChange={event => companyId.onChange(event.target.value)} />
            </MainContainer>
            <LoaderButton text="Connect" isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !companyId.value} type="submit" />
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInAlphaStaffApi" data-sentry-source-file="SignInAlphaStaffApi.tsx" />;
}