import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { RegisterImplementationProps } from 'constants/types';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import MainContainer from 'components/Container/MainContainer';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const RegisterZenefitsApi = ({
  client,
  provider,
  isLoading,
  onSubmit,
  handleClick,
  sandboxMode
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Login -&gt; menu (top right) -&gt; Company Profile -&gt; Custom
      Integrations -&gt; Add Token -&gt; Select All (or scopes relevant to the
      information you will be sharing) -&gt; Save
      <br />
      <br />
      For more information, click{' '}
      <AppLink target="_blank" href="https://help.zenefits.com/Integrated_Apps/Creating_Custom_Integrations_with_Zenefits/How_do_I_set_up_a_custom_integration%3F/" rel="noopener noreferrer">
        here
      </AppLink>
      .
    </>];
  if (sandboxMode === 'provider') {
    instructions.unshift(<>
        Sign up for a free Zenefits account{' '}
        <AppLink href="https://www.zenefits.com/learn/trial-signup/" target="_blank" rel="noreferrer">
          on their website
        </AppLink>{' '}
        and connect it here.
      </>);
  }
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterZenefitsApi.tsx">
        To connect your Zenefits account, you must create an API token for{' '}
        <strong>{client.name}</strong>.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterZenefitsApi.tsx" />
      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['apiToken']} render={({
      apiToken
    }) => <>
            <MainContainer>
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Token" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit" text="Connect" />
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterZenefitsApi.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Use a Username and Password instead
        </SwitchImplementationButton>}
    </>;
};
export default RegisterZenefitsApi;