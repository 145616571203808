import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import SignInDisclaimer from 'components/Text/SignInDisclaimer';
import MainContainer from 'components/Container/MainContainer';
import { getProviderFormLabel } from '../utils';
const RegisterQuickbooksCredential = ({
  provider,
  onSubmit,
  isLoading
}: RegisterImplementationProps) => <>
    <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => <>
          <MainContainer>
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="User ID" autoFocus />
            <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
            <SignInDisclaimer>
              A third-party accountant will be added.
            </SignInDisclaimer>
            {isLoading && provider.id === 'quickbooks' && <SignInDisclaimer>
                Keep the window open while we sign into your Quickbooks account.
                This could take a few minutes.
              </SignInDisclaimer>}
          </MainContainer>
          <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit" text="Connect" />
        </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterQuickbooksCredential.tsx" />
  </>;
export default RegisterQuickbooksCredential;