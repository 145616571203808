import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import PasswordInput from 'components/Input/PasswordInput';
import SignInDisclaimer from 'components/Text/SignInDisclaimer';
import MainContainer from 'components/Container/MainContainer';
import { ResetPasswordOnProviderLink } from 'components/Link/ResetPasswordOnProviderLink';
import { getProviderFormLabel } from '../utils';
const RegisterPaychexFlexCredential = ({
  provider,
  isLoading,
  onSubmit,
  handleClick
}: RegisterImplementationProps) => <>
    <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => <>
          <MainContainer>
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" autoFocus />
            <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
            <ResetPasswordOnProviderLink href="https://myapps.paychex.com/loginLite-rem/forgotCredentials.do#/" provider={provider.displayName} />
          </MainContainer>
          <SignInDisclaimer style={{
      marginBottom: '8px'
    }}>
            A third-party user with the &apos;Accountant&apos; role will be
            added.
          </SignInDisclaimer>
          <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit" text="Connect" />
        </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterPaychexFlexCredential.tsx" />
    {handleClick && <SwitchImplementationButton onClick={handleClick}>
        Use an API Token instead
      </SwitchImplementationButton>}
  </>;
export default RegisterPaychexFlexCredential;