import { useState } from 'react';
import rightArrow from 'assets/img/goto-arrow.svg';
import { authorize } from 'services/auth';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import styles from './ChooseAccount.module.scss';
import { step } from '../steps';
import GenericError from '../Errors/GenericError';
import LoadingSpinner from '../LoadingSpinner';
import { getStatusCode } from '../SignIn/utils';
import { ProviderAppShell } from '../ProviderAppShell';
const DEFAULT_ERROR_MESSAGE = 'Looks like there was an issue selecting an account. Please try again.';
const ChooseAccount = ({
  clientId,
  connectionId,
  clientName,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  payrollProvider,
  providerImplementation,
  currentStep,
  accounts,
  handleAuthorizeResponse,
  sandbox,
  appType,
  setError,
  implementationHasAssistedBenefits,
  setCurrentStep,
  error
}: AuthorizeContextValue) => {
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providerImplementation) throw new Error('no provider implementation');
  const [loading, setLoading] = useState(false);
  const [accountKey, setAccountKey] = useState<number | null>(null);
  const onChooseAccount = async (i: number) => {
    const chosenAccount = accounts[i];
    setAccountKey(i);
    setError(null);
    setLoading(true);
    try {
      const authorizeResponse = await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        step: currentStep,
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        chosenAccount,
        sandbox,
        appType,
        implementationHasAssistedBenefits
      });
      handleAuthorizeResponse(authorizeResponse);

      // If we are redirecting, we keep the loading state to avoid double submission.
      // Therefore, we only remove the loading state when there is no redirect.
      if (!('redirect' in authorizeResponse)) {
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      const message = err.message === 'Internal Server Error' ? DEFAULT_ERROR_MESSAGE : err.message ?? DEFAULT_ERROR_MESSAGE;
      setError({
        message,
        hideToast: true,
        finchCode: err.response?.data?.finch_code,
        status: getStatusCode(err)
      });
    }
  };
  if (loading) {
    return <div className={styles.container}>
        <LoadingSpinner />
      </div>;
  }
  if (error) {
    return <GenericError title="Error" buttonLabel="Back to Sign In" onClick={() => {
      setError(null);
      setCurrentStep(step.SIGN_IN);
    }}>
        {error.message}
      </GenericError>;
  }
  const accountsList = accounts.map((account, i) => <button type="button" className={[styles.accountContainer, 'ld-ext-right', accountKey === i && loading && 'running'].join(' ')} key={i} // eslint-disable-line react/no-array-index-key
  onClick={() => onChooseAccount(i)} tabIndex={0} disabled={loading}>
      <span className={styles.companyName}>{account.companyName}</span>
      <div className="ld ld-ring ld-spin" />
      {!loading && <img src={rightArrow} alt="" />}
    </button>);
  return <ProviderAppShell error={error} setError={setError} provider={payrollProvider} providerImplementation={providerImplementation} goBack={() => setCurrentStep(step.SIGN_IN)} headerText="Please select an account" data-sentry-element="ProviderAppShell" data-sentry-component="ChooseAccount" data-sentry-source-file="ChooseAccount.tsx">
      <div className={styles.accountsContainer}>{accountsList}</div>
    </ProviderAppShell>;
};
export default withAuthorizeContext(ChooseAccount);