import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { ResetPasswordOnProviderLink } from 'components/Link/ResetPasswordOnProviderLink';
import { getProviderFormLabel } from '../utils';
const RegisterWaveCredential = ({
  provider,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => <>
    <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => <>
          <MainContainer>
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Email" autoFocus />
            <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
            <ResetPasswordOnProviderLink href="https://my.waveapps.com/password/reset/" provider={provider.displayName} />
          </MainContainer>
          <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit" text="Connect" />
        </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterWaveCredential.tsx" />
  </>;
export default RegisterWaveCredential;