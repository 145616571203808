import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { getProviderFormLabel } from '../utils';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
const RegisterISolvedApi = ({
  provider,
  isLoading,
  onSubmit,
  client,
  instructionsLink
}: RegisterImplementationProps) => {
  const instructions = <>
      <p>
        To connect your {provider.displayName} account, you must get your Client
        Code for <strong>{client.name}</strong>.
      </p>
    </>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} instructionsLink={instructionsLink} onSubmit={onSubmit} divider fieldNames={['instructionCheck', 'isolvedUrl', 'username']} render={({
    instructionCheck,
    isolvedUrl,
    username
  }) => <>
          <MainContainer>
            <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
            <Input id="isolvedUrl" value={isolvedUrl.value} onChange={event => isolvedUrl.onChange(event.target.value)} invalid={isolvedUrl.invalid} label="iSolved URL" />
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Client ID on isolved product" />
          </MainContainer>
          <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value} type="submit" text="Connect" />
        </>} data-sentry-element="SignInBase" data-sentry-component="RegisterISolvedApi" data-sentry-source-file="SignInIsolvedApi.tsx" />;
};
export default RegisterISolvedApi;