import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import SignInDisclaimer from 'components/Text/SignInDisclaimer';
import MainContainer from 'components/Container/MainContainer';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import WarningMessage from '../../Messages/Warning';
import { getProviderFormLabel } from '../utils';
const RegisterGustoCredential = ({
  products,
  isLoading,
  onSubmit,
  handleClick,
  provider
}: RegisterImplementationProps) => <>
    {(products.includes('deduction') || products.includes('benefits')) && <WarningMessage>
        You must log in with a Primary Admin account.
      </WarningMessage>}
    <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => <>
          <MainContainer>
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Email address" autoFocus />
            <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
            <SignInDisclaimer>
              A third-party trusted advisor will be added.
            </SignInDisclaimer>
          </MainContainer>
          <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit" text="Connect" />
        </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterGustoCredential.tsx" />
    {handleClick && <SwitchImplementationButton onClick={handleClick}>
        Authenticate through Gusto
      </SwitchImplementationButton>}
  </>;
export default RegisterGustoCredential;