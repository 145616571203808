import _ from 'lodash';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { Clients } from 'constants/clients';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const clientsWithDifferentPermissions = new Set([...Object.values(Clients.Abacum)]);
const clientHasDifferentSetOfPermissions = (clientId: string) => clientsWithDifferentPermissions.has(clientId);
const RegisterBobApi = ({
  client,
  provider,
  products,
  isLoading,
  onSubmit,
  handleClick,
  sandboxMode
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const getBobPermissions = () => {
    const permissions = [];
    if (products.includes('directory')) {
      permissions.push('Lifecycle > View Lifecycle sections');
      permissions.push("Work > View selected employee's Work sections");
    }
    if (products.includes('individual')) {
      if (!clientHasDifferentSetOfPermissions(client.id)) {
        permissions.push('Address > View Address sections');
        permissions.push('Home > View');
      }
      permissions.push('Personal Contact Details > View');
      permissions.push('Personal > View');
      permissions.push('Identification > View');
    }
    if (products.includes('employment')) {
      if (!clientHasDifferentSetOfPermissions(client.id)) {
        permissions.push('Address > View Address sections');
      }
      permissions.push("Work > View selected employee's Work sections");
      permissions.push('Employment > View Employment sections');
      permissions.push('Lifecycle > View Lifecycle sections');
      permissions.push('Payroll > View Payroll sections');
    }
    if (products.includes('ssn')) {
      permissions.push('Identification > Edit');
    }
    const sortedPermissions = _.uniq(permissions).sort();
    const permissionsList = _.map(sortedPermissions, permission => <li key={permission}>
        <b>{permission}</b>
      </li>);
    return permissionsList;
  };
  const instructions: JSX.Element[] = [<>
      <AppLink target="_blank" href="https://developer.tryfinch.com/employer-resources/Bob" rel="noopener noreferrer">
        Follow these instructions
      </AppLink>{' '}
      to create a new API Service User. For both the name and display name, you
      can enter <strong>{client.name} Service User</strong>.
    </>, <>
      When creating the custom group, name the group{' '}
      <strong>{client.name} Service User Group</strong>. Please enable the
      following permissions:
      {getBobPermissions()}
    </>];
  if (sandboxMode === 'provider') {
    instructions.unshift(<>
        First make sure to{' '}
        <AppLink href="https://developer.tryfinch.com/docs/documentation/pzie4zvmok31z-provider-sandboxes#bob" target="_blank" rel="noreferrer">
          follow the setup instructions
        </AppLink>{' '}
        to get a test account for Bob.
      </>);
  }
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterBobApi.tsx">
        To connect your Bob (HiBob) account, you must create an API token for{' '}
        <strong>{client.name}</strong>.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterBobApi.tsx" />
      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['username', 'apiToken']} render={({
      username,
      apiToken
    }) => <>
            <MainContainer>
              <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Service User ID" />
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Token" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit" text="Connect" />
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterBobApi.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Use a Username and Password instead
        </SwitchImplementationButton>}
    </>;
};
export default RegisterBobApi;