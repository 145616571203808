import _ from 'lodash';
import { useState } from 'react';
import shortUUID from 'short-uuid';
import { AuthSuccessResponse, Category, Response as AuthorizeResponse, Sandbox, SelectCompany } from '@finch-api/common/dist/internal/connect/authorize';
import { ImplementationDetail, ProviderConfig, Response as ClientResponse, Session } from '@finch-api/common/dist/internal/connect/validate';
import { nextStepMap, nextStepMapReAuth, prevStepMap, prevStepMapReAuth, step } from 'pages/Authorize/steps';
import { SESSION_KEY } from 'utils/session';
import { getSpaMessage, getSpaRedirect, sendSdkMessage } from 'utils/sdk-channel';
import { usePopOutInstructionsLink } from 'store/pop-out-instructions';
import { ConnectError } from './types';
import { ImplementationBenefitsMethod } from '@finch-api/common/dist/db';
import { useHistory } from 'react-router-dom';
import { useAuthFailureCount } from 'store/auth-failure-count';
import { AuthorizeContext } from './AuthorizeContext';
import { StateRedirect, TerminalAuthState } from './types';
import { getStatusCode } from './SignIn/utils';
import { ERRORS_USERS_WILL_NOT_BE_ABLE_TO_RESOLVE, INTERMITTENT_ERRORS } from '../../constants/finch-error-code';
import { useSuccessPage } from 'store/use-success-page';
function isAuthSuccessResponse(response: AuthorizeResponse): response is AuthSuccessResponse {
  return 'redirect' in response;
}
export const isUnexpectedError = (error?: ConnectError | null) => {
  return error?.status && 500 <= error.status && error.status < 600 || error?.finchCode && [...INTERMITTENT_ERRORS, ...ERRORS_USERS_WILL_NOT_BE_ABLE_TO_RESOLVE].includes(error.finchCode);
};
export const AuthorizeProvider = ({
  children
}: {
  children: JSX.Element;
}) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState<string>(step.PREAMBLE);
  const [appType, setAppType] = useState<'ssr' | 'spa'>('ssr');
  const [category, setCategory] = useState(Category.HRIS);
  const [clientId, setClientId] = useState<string>('');
  const [providerId, setProviderId] = useState<string>('');
  const {
    authFailureCounts,
    setAuthFailureCounts
  } = useAuthFailureCount();
  const {
    setSuccessPageRedirectUrl
  } = useSuccessPage();
  const [connectionId, setConnectionId] = useState<string | undefined>(undefined);
  const [connection, setConnection] = useState<Session['connection'] | undefined>(undefined);
  const [clientName, setClientName] = useState<string | undefined>(undefined);
  const [redirectUri, setRedirectUri] = useState<string>('');
  const [products, setProducts] = useState<string[]>();
  const [sessionKey, setSessionKey] = useState<string>(SESSION_KEY);
  const [currentBrowserSessionKey, setCurrentBrowserSessionKey] = useState(SESSION_KEY);
  const [state, setState] = useState<string>();
  const [client, setClient] = useState<ClientResponse>();
  const [providersConfig, setProvidersConfig] = useState<ProviderConfig[]>();
  const [payrollProvider, setPayrollProvider] = useState<ProviderConfig>();
  const [providerImplementation, setProviderImplementation] = useState<ImplementationDetail>();
  const [manual, setManual] = useState<boolean>(false);
  const [sandbox, setSandbox] = useState<Sandbox>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [accounts, setAccounts] = useState<SelectCompany[]>([]);
  const [mfaType, setMfaType] = useState<string>();
  const [mfaReason, setMfaReason] = useState<string>();
  const [challengeQuestion, setChallengeQuestion] = useState<string>();
  const [authorizeLoading, setAuthorizeLoading] = useState<boolean>(false);
  const [challengeQuestionRetry, setChallengeQuestionRetry] = useState<string | boolean>();
  const [email, setEmail] = useState<string>();
  const [sdkVersion, setSdkVersion] = useState<string>();
  const [hasBenefitsProduct, setHasBenefitsProduct] = useState<boolean>(false);
  const [idpRedirect, setIdpRedirect] = useState<boolean>(false);
  const [preSetImplementationKind, setPreSetImplementationKind] = useState<string | null>(null);
  const [mfaCode, setMfaCode] = useState<string | undefined>(undefined);
  const [securityCode, setSecurityCode] = useState<string>();
  const implementationHasAssistedBenefits = providerImplementation?.benefitsMethod === ImplementationBenefitsMethod.ASSISTED;
  const [contactEmail, setContactEmail] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [error, reactSetError] = useState<ConnectError | null>(null);
  const setPopOutInstructionsLink = usePopOutInstructionsLink(state => state.setPopOutInstructionsLink);
  const postSdkError = (opts: {
    error: ConnectError;
    sdkNamespaceVersions?: ('v1' | 'v2')[];
  }) => {
    const {
      error,
      sdkNamespaceVersions
    } = opts;
    if (appType !== 'spa') return;

    /**
     * We want to send only validation errors to both v1 and v2 of the SDK namespace.
     * For all other errors, we only send to v2 of the SDK namespace, since v1 of the
     * React SDK closes the auth window when it encounters any error.
     */
    const defaultNamespaceVersions: ('v1' | 'v2')[] = error.type === 'validation_error' ? ['v1', 'v2'] : ['v2'];
    const namespaceVersions = sdkNamespaceVersions || defaultNamespaceVersions;
    const message = getSpaMessage({
      error
    });
    const url = getSpaRedirect();
    sendSdkMessage({
      message,
      url,
      namespaceVersions
    });
  };
  const setError = (error: ConnectError | null) => {
    reactSetError(error);
    if (error?.status && providerImplementation) {
      if (isUnexpectedError(error)) {
        const failures = authFailureCounts[providerImplementation.id] ?? 0;
        setAuthFailureCounts({
          ...authFailureCounts,
          [providerImplementation.id]: failures + 1
        });
      }
    }
    if (error) {
      postSdkError({
        error
      });
    }
  };
  const [providerToRedirect, setProviderToRedirect] = useState<string | null>(null);
  const setProductsWrapper = (products: string[]) => {
    setProducts(products);
    setHasBenefitsProduct(products.includes('benefits'));
    return products;
  };
  const nextStep = () => {
    if (connectionId) {
      // we are in re-auth mode
      setCurrentStep(nextStepMapReAuth[currentStep]);
    } else {
      setCurrentStep(nextStepMap[currentStep]);
    }
  };
  const prevStep = () => {
    if (connectionId) {
      // we are in re-auth mode
      setCurrentStep(prevStepMapReAuth[currentStep]);
    } else {
      setCurrentStep(prevStepMap[currentStep]);
    }
    setPopOutInstructionsLink(null);
    setError(null);
  };
  const completeAuth = (terminalAuthState: TerminalAuthState) => {
    const redirectTo: string = 'redirectTo' in terminalAuthState ? terminalAuthState.redirectTo : '';
    const forceSpa = 'forceSpa' in terminalAuthState ? terminalAuthState.forceSpa : undefined;
    if (appType === 'spa' || forceSpa) {
      const url = getSpaRedirect({
        redirectUri
      });
      if ('redirectTo' in terminalAuthState) {
        sendSdkMessage({
          message: getSpaMessage({
            redirectTo
          }),
          url
        });
      }
      if ('error' in terminalAuthState) {
        sendSdkMessage({
          message: getSpaMessage({
            error: {
              message: terminalAuthState.error,
              type: terminalAuthState.errorType,
              status: getStatusCode(terminalAuthState.error)
            }
          }),
          url
        });
      }
      if ('closed' in terminalAuthState) {
        sendSdkMessage({
          message: getSpaMessage({
            closed: true
          }),
          url
        });
      }
    }
    if (redirectTo) {
      const redirectUrl = new URL(redirectTo);
      if (window.location.origin === redirectUrl.origin) {
        const path = redirectUrl.pathname + redirectUrl.search + redirectUrl.hash;
        history.push(path);
      } else {
        window.location.href = redirectTo;
      }
    }
  };
  const handleStateRedirect = ({
    next,
    nextContext
  }: StateRedirect) => {
    const {
      redirect,
      payrollProviderId
    } = nextContext;
    if (redirect) setPayrollProvider(_.find(providersConfig, {
      id: payrollProviderId
    }));
    setCurrentStep(next);
  };
  const handleAuthorizeResponse = (authorizeResponse: AuthorizeResponse) => {
    // When it's an auth success response, we can complete auth.
    if (isAuthSuccessResponse(authorizeResponse)) {
      const {
        redirect
      } = authorizeResponse;
      setCurrentStep(step.SESSION_COMPLETE);
      setAuthorizeLoading(false);
      setSuccessPageRedirectUrl(redirect);
      return;
    }

    // Only set authorizeLoading to false when we're navigating to a different page
    // This is to prevent showing the sign in screen before Connect sends the developer the auth code
    setAuthorizeLoading(false);

    // when it's a auth processing response, we can move to the next step
    const {
      next,
      nextContext
    } = authorizeResponse;
    if (next === 'MFA' && nextContext) {
      const {
        phoneNumber,
        email,
        mfaType,
        mfaReason,
        challengeQuestion,
        challengeQuestionRetry
      } = nextContext;
      if (phoneNumber) setPhoneNumber(phoneNumber);
      if (email) setEmail(email);
      if (mfaType) setMfaType(mfaType);
      if (mfaReason) setMfaReason(mfaReason);
      if (challengeQuestion) setChallengeQuestion(challengeQuestion);
      if (challengeQuestionRetry) {
        setChallengeQuestionRetry(challengeQuestionRetry);
        setError({
          message: 'Your answer was incorrect - please answer the new question',
          type: 'employer_connection_error',
          status: null
        });
      } else {
        setChallengeQuestionRetry(false);
      }
    }

    // We only come to this step when there are multiple accounts to choose from
    if (next === 'CHOOSE_ACCOUNT') {
      const {
        accounts
      } = nextContext;
      setAccounts(accounts || []);
    }
    if (next === 'ASSISTED_BENEFITS') {
      const {
        companyName,
        contactEmail
      } = nextContext;
      setCompanyName(companyName);
      setContactEmail(contactEmail);
    }
    if (next === 'CAPTCHA') {
      const {
        mfaCode
      } = nextContext;
      setMfaCode(mfaCode);
    }
    if (next === 'SECURITY_CHALLENGE') {
      const {
        securityCode
      } = nextContext;
      setSecurityCode(securityCode);
    }
    setCurrentStep(next);
  };
  return <AuthorizeContext.Provider value={{
    completeAuth,
    appType,
    setAppType,
    category,
    setCategory,
    clientId,
    setClientId,
    providerId,
    setProviderId,
    clientName,
    setClientName,
    redirectUri,
    setRedirectUri,
    products,
    setProductsWrapper,
    hasBenefitsProduct,
    setHasBenefitsProduct,
    contactEmail,
    setContactEmail,
    companyName,
    setCompanyName,
    currentStep,
    setCurrentStep,
    connection,
    setConnection,
    connectionId,
    setConnectionId,
    client,
    setClient,
    currentBrowserSessionKey,
    setCurrentBrowserSessionKey,
    sessionKey,
    setSessionKey,
    state,
    setState,
    providersConfig,
    setProvidersConfig,
    payrollProvider,
    setPayrollProvider,
    providerImplementation,
    setProviderImplementation,
    manual,
    setManual,
    sandbox,
    setSandbox,
    phoneNumber,
    setPhoneNumber,
    nextStep,
    prevStep,
    accounts,
    setAccounts,
    handleAuthorizeResponse,
    handleStateRedirect,
    mfaType,
    setMfaType,
    challengeQuestion,
    setChallengeQuestion,
    challengeQuestionRetry,
    setChallengeQuestionRetry,
    email,
    setEmail,
    sdkVersion,
    setSdkVersion,
    error,
    setError,
    providerToRedirect,
    authorizeLoading,
    setAuthorizeLoading,
    setProviderToRedirect,
    mfaReason,
    setMfaReason,
    implementationHasAssistedBenefits,
    idpRedirect,
    setIdpRedirect,
    preSetImplementationKind,
    setPreSetImplementationKind,
    postSdkError,
    mfaCode,
    setMfaCode,
    securityCode,
    setSecurityCode
  }} data-sentry-element="unknown" data-sentry-component="AuthorizeProvider" data-sentry-source-file="AuthorizeProvider.tsx">
      {children}
    </AuthorizeContext.Provider>;
};