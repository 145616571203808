import {
  ImplementationDetail,
  ProviderConfig,
  Response as ClientResponse,
  Session,
} from '@finch-api/common/dist/internal/connect/validate';
import { ConnectError } from 'pages/Authorize/types';
import { Sandbox } from '@finch-api/common/dist/internal/connect/authorize';
import { SubmitOptions } from '../pages/Authorize/SignIn/SignIn';

export enum PayrollProviderId {
  ADP_RUN = 'adp_run',
  ADP_WORKFORCE_NOW = 'adp_workforce_now',
  ALPHASTAFF = 'alphastaff',
  APPLIED_BUSINESS_SOLUTIONS = 'applied_business_solutions',
  BAMBOO_HR = 'bamboo_hr',
  BOB = 'bob',
  CERIDIAN_DAYFORCE = 'ceridian_dayforce',
  COUNTERPOINT_HCM = 'counterpoint_hcm',
  DEEL = 'deel',
  FACTORIAL_HR = 'factorial_hr',
  FINCH = 'finch',
  GUSTO = 'gusto',
  HUMAANS = 'humaans',
  HYBRID = 'hybrid',
  HORIZON_PAYROLL = 'horizonpayroll',
  INSPERITY = 'insperity',
  ISOLVED = 'isolved',
  JUSTWORKS = 'justworks',
  NAMELY = 'namely',
  OYSTER_HR = 'oyster_hr',
  ONPAY = 'onpay',
  PAYCHEX_FLEX = 'paychex_flex',
  PAYCOM = 'paycom',
  PAYCOR = 'paycor',
  PAYLOCITY = 'paylocity',
  PERSONIO = 'personio',
  QUICKBOOKS = 'quickbooks',
  RIPPLING = 'rippling',
  SAGE_HR = 'sage_hr',
  SAPLING = 'sapling',
  SEQUOIA_ONE = 'sequoia_one',
  SQUARE_PAYROLL = 'square_payroll',
  TRINET = 'trinet',
  UKG_READY = 'ukg_ready',
  ULTI_PRO = 'ulti_pro',
  WAVE = 'wave',
  WORKDAY = 'workday',
  ZENEFITS = 'zenefits',
}

export interface RegisterProviderContainerProps {
  client: ClientResponse;
  provider: ProviderConfig;
  products: string[];
  hasBenefitsProduct: boolean;
  currentStep: string;
  implementationDetail: ImplementationDetail;
  children: React.ReactNode;
  sandboxMode: Sandbox;
  error: ConnectError | null;
  setError: (error: ConnectError | null) => void;
  connection?: Session['connection'];
}

// new implementation level props
export interface RegisterImplementationProps {
  client: ClientResponse;
  provider: ProviderConfig;
  implementationDetail: ImplementationDetail;
  isLoading: boolean;
  onSubmit: (fieldToValueMap: SubmitOptions) => Promise<void>;
  products: string[];
  handleClick?: () => void;
  sandboxMode: Sandbox;
  onMockOAuth: () => Promise<boolean>;
  instructionsLink?: string;
}

export class ClientBlockedError extends Error {
  clientName?: string;

  constructor(clientName?: string) {
    super('Client is blocked');
    this.clientName = clientName;
  }
}
