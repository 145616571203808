import { datadogRum } from '@datadog/browser-rum';
import { useUnleashContext } from '@unleash/proxy-client-react';
import { clsx } from 'clsx';
import * as Sentry from '@sentry/react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Category, Sandbox } from '@finch-api/common/dist/internal/connect/authorize';
import { Clients } from 'constants/clients';
import { paramAppType, paramCategory, paramClientId, paramClientName, paramConnectionId, paramManual, paramPayrollProvider, paramProducts, paramRedirectUri, paramSandbox, paramSdkVersion, paramSessionId, paramState } from 'constants/params';
import { FINCH_DOCS_BASE_URL } from 'constants/urls';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { step, stepMap } from 'pages/Authorize/steps';
import { validate, validateAppType, validateCategory, validateSessionId } from 'services/auth';
import { AnalyticsEventName, identifySession, track } from 'utils/analytics';
import styles from './Authorize.module.scss';
import InvalidSandboxApplication from './Errors/InvalidSandboxApplication';
import SandboxAccessDenied from './Errors/SandboxAccessDenied';
import ProviderSandboxConnectionLimit from './Errors/ProviderSandboxConnectionLimit';
import { query } from 'utils/params';
import { CanNotUseAssistedInSandbox } from './Errors/CanNotUseAssisted';
import FinchSandboxConnectionLimit from './Errors/FinchSandboxConnectionLimit';
import { useStagedConfigStore } from './useStagedConfig';
import { EMPLOYER_PREFIX } from 'constants/products';
import GenericError from './Errors/GenericError';
import { ProviderConfig, Session } from '@finch-api/common/dist/internal/connect/validate';
import _ from 'lodash';
import { getStatusCode } from './SignIn/utils';
import { ClientBlockedError } from '../../constants/types';
import { ClientBlocked } from './Errors/ClientBlocked';
import { CloseButton } from 'components/Button/CloseButton';
import { ExitPage } from './ExitPage';
import { AppLink } from 'components/Link/AppLink';
import { useShowConfirmClose } from 'store/confirm-close';
import { usePopOutInstructionsLink } from 'store/pop-out-instructions';
import { ConnectError } from './types';
const TopBar = ({
  text
}: {
  text: React.ReactNode;
}) => <div className={styles.testingBar} data-sentry-component="TopBar" data-sentry-source-file="Authorize.tsx">{text}</div>;
export const NO_REFERRER_URL = 'no-referrer-found';
const sessionErrorMap = {
  'Session not found': 'This session link is invalid',
  'Session expired': 'This session link has expired',
  'Session link already used': 'This session link has already been used to get connected. Feel free to close this window'
};
export const withSandboxURL = () => {
  const url = new URL(window.location.href);
  const qp = url.searchParams;
  qp.set(paramSandbox, 'true');
  url.search = qp.toString();
  return url.toString();
};
const parseSandbox = (sandbox: string): Sandbox => {
  switch (sandbox) {
    case 'finch':
    case 'provider':
      return sandbox;
    case 'true':
    case '1':
      return true;
    case 'false':
    case '0':
    default:
      return false;
  }
};
const AuthorizeContent = ({
  currentStep,
  cannotUseAssistedForSandbox,
  sandboxMode,
  clientBlockedError,
  error
}: {
  currentStep: string;
  cannotUseAssistedForSandbox?: boolean;
  sandboxMode?: Sandbox;
  clientBlockedError?: ClientBlockedError;
  error?: ConnectError | null;
}) => {
  if (clientBlockedError) {
    return <ClientBlocked clientName={clientBlockedError.clientName} />;
  }
  if (cannotUseAssistedForSandbox && sandboxMode) {
    return <CanNotUseAssistedInSandbox mode={sandboxMode} />;
  }
  if (error && error.isValidateError) {
    if (error.message === 'Finch Sandbox is not available') return <SandboxAccessDenied />;
    if (error.message === 'Invalid Client For Provider Sandbox') return <InvalidSandboxApplication />;
    if (error.message === 'Provider Sandbox Connection Limit Reached') {
      return <ProviderSandboxConnectionLimit />;
    }
    if (error.message === 'Finch Sandbox Connection Limit Reached') return <FinchSandboxConnectionLimit />;
    if (Object.keys(sessionErrorMap).includes(error.message)) {
      return <GenericError title="There was an error with this session">
          {sessionErrorMap[error.message as keyof typeof sessionErrorMap]}
        </GenericError>;
    }
    return <GenericError title="An error occurred">{error.message}</GenericError>;
  }
  return stepMap[currentStep]();
};
const Authorize = ({
  location,
  currentStep,
  clientId,
  setClientId,
  setProviderId,
  setConnectionId,
  setConnection,
  setClientName,
  setRedirectUri,
  setProductsWrapper,
  setClient,
  setState,
  setAppType,
  setCategory,
  setCurrentStep,
  setPayrollProvider,
  setProviderImplementation,
  setProvidersConfig,
  manual,
  setManual,
  setSandbox,
  setSdkVersion,
  completeAuth,
  appType,
  client,
  sessionKey,
  currentBrowserSessionKey,
  setSessionKey,
  sandbox,
  error,
  setError,
  skipParamValidation = false,
  preSetImplementationKind,
  setPreSetImplementationKind,
  setIdpRedirect
}: {
  location: {
    search: string;
  };
  skipParamValidation: boolean;
} & AuthorizeContextValue) => {
  const history = useHistory();
  const {
    setConfirmCloseTrue,
    setConfirmCloseFalse,
    confirmClose
  } = useShowConfirmClose();

  /**
   * Redirecting within the iFramed Connect opened by the SDK changes the document.referrer to the Connect URL,
   * so we need to capture the original referrer URL in case a redirect happens. The document.referrer is used to send
   * postMessages back to the SDK.
   */
  if (query('app_type').toLowerCase().trim() === 'spa' && !query('sdk_host_url')) {
    const sdkHostUrl = document?.referrer || NO_REFERRER_URL;
    Sentry.addBreadcrumb({
      category: 'sdk-events',
      message: `Reloading URL with the sdk_host_url ${sdkHostUrl}`,
      data: {
        sdkHostUrl
      },
      level: 'info'
    });
    history.replace({
      pathname: '/authorize',
      search: `${location.search}&sdk_host_url=${sdkHostUrl}`
    });
  }
  const setSession = (session: Session, providersConfig: ProviderConfig[]) => {
    setClientId(session.clientId);
    if (session.providerId) {
      setProviderId(session.providerId);
      setPayrollProvider(_.find(providersConfig, {
        id: session.providerId
      }));
    }
    setRedirectUri(session.redirectUri);
    setSandbox(session.sandbox);
    setManual(session.manual ?? false);
    setClientId(session.clientId);
    const products = setProductsWrapper(session.products.map(product => product.replace(EMPLOYER_PREFIX, '')) || []);
    setPreSetImplementationKind(session.implementationKind);
    setIdpRedirect(session.idp);
    setClientName(session.clientName);
    if (session.connectionId) {
      setConnectionId(session.connectionId);
    }
    if (session.connection) {
      setConnection(session.connection);
    }
    return {
      products
    };
  };
  const stagedConfig = useStagedConfigStore(state => state.stagedConfig);
  const showInstructions = usePopOutInstructionsLink(state => state.popOutInstructionsLink);
  const updateUnleashContext = useUnleashContext();
  const [clientBlockedError, setClientBlockedError] = useState<ClientBlockedError | undefined>();
  useEffect(() => {
    track(AnalyticsEventName.ViewedConnectStep, {
      step: currentStep,
      currentBrowserSessionKey
    });
  }, [currentStep, currentBrowserSessionKey]);
  useEffect(() => {
    /* Datadog RUM has a quirk where actions that happen when a new view is registered are ignored.
    The timeout is to ensure we capture click actions on the previous view before the new view is registered */
    setTimeout(() => {
      datadogRum.startView({
        name: currentStep
      });
    }, 500);
  }, [currentStep]);
  useEffect(() => {
    const paramValidation = async () => {
      const sessionId = query(paramSessionId);
      const clientId = query(paramClientId);
      const connectionId = query(paramConnectionId) || undefined;
      const redirectUri = query(paramRedirectUri);
      const rawProducts = query(paramProducts);
      const state = query(paramState) || undefined;
      const appType = query(paramAppType) as 'ssr' | 'spa';
      const category = (query(paramCategory) || Category.HRIS) as Category;
      const payrollProviderId = query(paramPayrollProvider);
      const manualQuery = query(paramManual);
      const sandbox = query(paramSandbox);
      const clientName = query(paramClientName) || undefined;
      // TODO not used currently. Will be used for monitoring
      const sdkVersion = query(paramSdkVersion);
      try {
        if (category) {
          validateCategory(category);
          setCategory(category);
        }
        if (appType) {
          validateAppType(appType);
          setAppType(appType);
        }
        const sandboxValue = parseSandbox(sandbox);
        setState(state);
        setSdkVersion(sdkVersion);
        updateUnleashContext({
          userId: sessionKey,
          properties: {
            clientId: clientId || ''
          }
        });
        if (sessionId) {
          setSessionKey(sessionId);
        }
        if (clientName) {
          setClientName(clientName);
        }
        const client = sessionId ? await validateSessionId({
          sessionId,
          redirectUri: redirectUri.trim() || undefined,
          products: rawProducts ? rawProducts.split(' ').map(product => product.trim()).filter(Boolean) : undefined,
          currentBrowserSessionKey
        }) : await validate({
          clientId,
          redirectUri,
          connectionId,
          products: rawProducts.split(' '),
          category,
          sessionKey,
          currentBrowserSessionKey,
          manual: manualQuery === 'true' || manualQuery === '1',
          sandbox: sandboxValue,
          providerId: payrollProviderId,
          clientName
        });
        setSession(client.session, client.providersConfig);
        identifySession({
          sessionKey: sessionId || sessionKey,
          currentBrowserSessionKey,
          clientId,
          session: client.session,
          clientName: client.name
        });
        datadogRum.setGlobalContext({
          scopes: rawProducts,
          clientId,
          redirectUri,
          state,
          appType,
          category,
          payrollProviderId,
          manual: manualQuery,
          sandbox,
          sdkVersion,
          applicationName: client?.name
        });
        Sentry.setTags({
          application_id: clientId,
          application_name: client.name,
          provider_id: payrollProviderId
        });
        Sentry.setContext('authorize', {
          scopes: rawProducts,
          clientId,
          redirectUri,
          state,
          appType,
          category,
          payrollProviderId,
          manual: manualQuery,
          sandbox,
          sdkVersion,
          applicationName: client?.name
        });
        setClient(client);
        setProvidersConfig(client.providersConfig);

        // this part should be removed after auth config rollout
        // we should check the length of the providerConfig
        // and pass select screen if return configs has only length = 1
        // this should be true for applications that only have 1 provider in config
        if (payrollProviderId) {
          setPayrollProvider(client.providersConfig[0]);
        }
        const selectedProvider = client.providersConfig.find(provider => provider.id === payrollProviderId);
        const selectedImplementation = selectedProvider?.implementations.find(implementation => implementation.kind === preSetImplementationKind);
        setProviderImplementation(selectedImplementation);
        if (client.session.connectionId) {
          const payrollProvider = client.providersConfig[0];
          setPayrollProvider(payrollProvider);
          setProviderImplementation(payrollProvider?.implementations[0]);
        }

        // Go directly to provider selection if the skipConnectAgreement flag is set
        if (client?.settings?.skipConnectAgreement) {
          setCurrentStep(step.SELECT_PROVIDER);
        }
      } catch (err) {
        if (err instanceof Error) {
          if (err instanceof ClientBlockedError) {
            setClientBlockedError(err);
          } else if (appType === 'spa') {
            completeAuth({
              error: err.message,
              errorType: 'validation_error',
              forceSpa: true
            });
          } else {
            setError({
              message: err.message,
              status: getStatusCode(err),
              isValidateError: true
            });
          }
        }
      }
    };
    if (!skipParamValidation) {
      paramValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onClose = () => {
    if (appType === 'spa') {
      completeAuth({
        closed: true
      });
    }
  };
  const sandboxDocumentation = (modeName: Exclude<Sandbox, boolean>) => {
    const capitalizedMode = modeName.charAt(0).toUpperCase() + modeName.slice(1);
    const urlSuffix = modeName === 'finch' ? 'Finch-Sandbox#simulating-credential-flows' : 'Provider-Test-Environments';
    return <span data-sentry-component="sandboxDocumentation" data-sentry-source-file="Authorize.tsx">
        You are currently in {capitalizedMode} Sandbox Mode. Visit our{' '}
        <AppLink href={`${FINCH_DOCS_BASE_URL}/implementation-guide/Test/${urlSuffix}`} target="_blank" rel="noreferrer" onClick={() => track(AnalyticsEventName.ClickedSandboxInstructions)} data-sentry-element="AppLink" data-sentry-source-file="Authorize.tsx">
          sandbox documentation
        </AppLink>{' '}
        for the login/password and more information.
      </span>;
  };
  const sandboxText = useMemo(() => {
    switch (sandbox) {
      case true:
        return sandboxDocumentation('finch');
      case 'finch':
      case 'provider':
        return sandboxDocumentation(sandbox);
      default:
        return <span>
            Your application can only be used in Sandbox mode.{' '}
            <AppLink href={withSandboxURL()}>Connect</AppLink> your application
            to a sandbox provider!
          </span>;
    }
  }, [sandbox]);
  return <div className={styles.outerContainer} onClick={() => {
    if (appType === 'spa') {
      setConfirmCloseTrue();
    }
  }} data-sentry-component="Authorize" data-sentry-source-file="Authorize.tsx">
      {/* This is the Finch Homepage client, API Server doesn't actually invoke
       the Adapter with these credentials, instead it replaces them and mocks a
       response. ie you can choose Gusto, sign in with bogus email/password and
       it won't ever send these to Gusto! */}
      {clientId === Clients.Finch.Homepage && <TopBar text="You are currently in Demo mode. Enter any credentials to log in." />}
      {client?.status === 'SANDBOX' && <TopBar text={sandboxText} />}
      {stagedConfig && <TopBar text="You are currently in Preview mode" />}

      <div className={clsx(styles.container, showInstructions && styles.withInstructions)} onClick={e => {
      e.stopPropagation();
    }}>
        {appType === 'spa' && <CloseButton />}

        {confirmClose && <ExitPage onClose={onClose} onGoBack={() => setConfirmCloseFalse()} />}

        <AuthorizeContent currentStep={currentStep} cannotUseAssistedForSandbox={sandbox === 'provider' && manual === true} sandboxMode={sandbox} clientBlockedError={clientBlockedError} error={error} data-sentry-element="AuthorizeContent" data-sentry-source-file="Authorize.tsx" />
      </div>
    </div>;
};
export default withAuthorizeContext(Authorize);