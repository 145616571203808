import { ImplementationDetail, ProviderConfig } from '@finch-api/common/dist/internal/connect/validate';
import { ERRORS_A_DIFFERENT_USER_NEEDS_TO_AUTHENTICATE, ERRORS_USERS_CAN_RESOLVE_WITHIN_CONNECT, ERRORS_USERS_NEEDS_TO_LEAVE_CONNECT_TO_SOLVE } from 'constants/finch-error-code';
import { shouldTriggerAuthFallback } from 'lib/auth-fallback/utils';
import { useAuthFailureCount } from 'store/auth-failure-count';
import { useAuthorizeContext } from '../AuthorizeContext';
import { AuthFallbackThresholdError } from '../Errors/AuthFallbackThresholdError';
import { isUnexpectedError } from '../AuthorizeProvider';
import { CriticalAuthError } from '../Errors/CriticalAuthError';
import { step } from '../steps';
import { ConnectError } from '../types';
import { Box } from '@chakra-ui/react';
import Notifications from 'components/Notifications/Notifications';
const GLOBAL_UNEXPECTED_ERROR_COUNT_THRESHOLD = 5;
export const ProviderAppShellError = ({
  error,
  setError,
  providerImplementation,
  instructionsLink,
  provider
}: {
  provider: ProviderConfig;
  providerImplementation: ImplementationDetail;
  setError: (error: ConnectError | null) => void;
  error: ConnectError;
  instructionsLink?: string;
}) => {
  const {
    client,
    currentStep,
    setCurrentStep
  } = useAuthorizeContext() || {};
  const authFailureCounts = useAuthFailureCount(state => state.authFailureCounts);
  const authFallbackThresholdReached = shouldTriggerAuthFallback(client, authFailureCounts, providerImplementation);
  const currentProviderErrorCount = authFailureCounts[providerImplementation.id];
  const userNeedsToLeaveConnect = error?.finchCode && ERRORS_USERS_NEEDS_TO_LEAVE_CONNECT_TO_SOLVE.includes(error.finchCode);
  const aDifferentUserNeedsToAuthenticate = error?.finchCode && ERRORS_A_DIFFERENT_USER_NEEDS_TO_AUTHENTICATE.includes(error.finchCode);
  const userCanResolveError = error?.finchCode && ERRORS_USERS_CAN_RESOLVE_WITHIN_CONNECT.includes(error.finchCode);
  if (userNeedsToLeaveConnect) {
    return <CriticalAuthError errorMessage={error.message} onButtonClick={() => setError(null)} buttonLabel="Try again" />;
  }
  if (aDifferentUserNeedsToAuthenticate) {
    return <CriticalAuthError errorMessage={error.message} instructionsLink={instructionsLink} />;
  }
  if (isUnexpectedError(error)) {
    if (authFallbackThresholdReached) {
      return <AuthFallbackThresholdError providerName={provider.displayName} onNext={() => {
        setError?.(null);
        setCurrentStep?.(step.MANUAL_SIGN_IN);
      }} />;
    }
    if (currentProviderErrorCount >= GLOBAL_UNEXPECTED_ERROR_COUNT_THRESHOLD) {
      return <CriticalAuthError headerText="Please try again later" errorMessage={`We're currently experiencing connection issues with ${provider.displayName}. Please reach out to ${client?.name} for more assistance. Or you can come back later to try again.`} />;
    }
    if (currentStep === step.MFA) {
      return <CriticalAuthError headerText="An unexpected error has occurred" buttonLabel="Back to Sign In" errorMessage="Please restart the authentication process" onButtonClick={() => {
        setError(null);
        setCurrentStep?.(step.SIGN_IN);
      }} />;
    }

    // If we specify a finchCode, we can show a more specific error message
    if (error?.finchCode) {
      return <CriticalAuthError buttonLabel="Try again" errorMessage={error.message} onButtonClick={() => setError?.(null)} />;
    }
    return <CriticalAuthError buttonLabel="Try again" onButtonClick={() => setError?.(null)} />;
  }
  if (userCanResolveError) {
    return <Box width="100%" position="sticky" top="0" my="2">
        <Notifications error={error} setError={setError} />
      </Box>;
  }
  return <CriticalAuthError buttonLabel="Try again" errorMessage={error.message} onButtonClick={() => setError?.(null)} data-sentry-element="CriticalAuthError" data-sentry-component="ProviderAppShellError" data-sentry-source-file="ProviderAppShellError.tsx" />;
};