import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { getProviderFormLabel } from '../utils';
const RegisterNamelyCredential = ({
  isLoading,
  onSubmit,
  handleClick,
  provider
}: RegisterImplementationProps) => <>
    <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['subdomain', 'username', 'password']} render={({
    subdomain,
    username,
    password
  }) => <>
          <MainContainer>
            <Input id="subdomain" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} label="Subdomain (from <subdomain>.namely.com)" autoFocus />
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Email" />
            <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
          </MainContainer>
          <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit" text="Connect" />
        </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterNamelyCredential.tsx" />
    {handleClick && <SwitchImplementationButton onClick={handleClick}>
        Use an API Token instead
      </SwitchImplementationButton>}
  </>;
export default RegisterNamelyCredential;