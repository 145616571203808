import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import { getProviderFormLabel } from '../utils';
const SignInCeridianDayforceApi = ({
  provider,
  isLoading,
  onSubmit,
  instructionsLink
}: RegisterImplementationProps) => {
  return <SignInBase formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} instructionsLink={instructionsLink} fieldNames={['clientCode', 'username', 'password']} render={({
    clientCode,
    username,
    password
  }) => {
    return <>
            <MainContainer useNewDesign>
              <Input value={clientCode.value} onChange={event => clientCode.onChange(event.target.value)} invalid={clientCode.invalid} label="Company" id="clientCode" />
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" id="username" />
              <PasswordInput reset={{
          uri: new URL('https://www.dayforcehcm.com/MyDayforce/MyDayforce.aspx'),
          providerDisplayName: provider.displayName
        }} value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !clientCode.value || !username.value || !password.value} type="submit" text="Connect" />
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInCeridianDayforceApi" data-sentry-source-file="SignInCeridianDayforceApi.tsx" />;
};
export default SignInCeridianDayforceApi;