import _ from 'lodash';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const RegisterUltiProApi = ({
  client,
  provider,
  products,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const permissions = [];
  if (products.includes('company')) {
    permissions.push('Company Configuration Integration');
    permissions.push('Personnel Integration');
  }
  if (products.includes('directory') || products.includes('individual')) {
    permissions.push('Employee Person Details');
    permissions.push('Personnel Integration');
  }
  if (products.includes('employment')) {
    permissions.push('Employee Person Details');
    permissions.push('Personnel Integration');
    permissions.push('Employee Job History Details');
    permissions.push('Company Configuration Integration');
  }
  if (products.includes('payment') || products.includes('pay_statement')) {
    permissions.push('Employee Pay Statement');
    permissions.push('Payroll Integration');
  }
  const uniqPermissions = _.uniq(permissions).sort();
  const permissionsList = uniqPermissions.map(permission => <li key={permission}>
      <b>{permission}</b>
    </li>);
  const instructions: JSX.Element[] = [<>
      Please follow the instructions{' '}
      <AppLink target="_blank" href="https://developer.tryfinch.com/employer-resources/UKG-Pro" rel="noopener noreferrer">
        here
      </AppLink>
      . You will need the following permissions: {permissionsList}
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterUltiProApi.tsx">
        To connect your UltiPro (UKG Pro) account, you must create a service
        account for <strong>{client.name}</strong>. If you already have a
        service account, you may use that one.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterUltiProApi.tsx" />
      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['fullDomain', 'username', 'password', 'apiToken', 'tenantId']} render={({
      fullDomain,
      username,
      password,
      apiToken,
      tenantId
    }) => <>
            <MainContainer>
              <Input id="fullDomain" value={fullDomain.value} onChange={event => fullDomain.onChange(event.target.value)} invalid={fullDomain.invalid} label="Web Service URL (example: service4.ultipro.com)" />
              <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="User Name" />
              <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="Customer API Key" />
              <Input id="tenantId" value={tenantId.value} onChange={event => tenantId.onChange(event.target.value)} invalid={tenantId.invalid} label="User API Key" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit" text="Connect" />
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterUltiProApi.tsx" />
    </>;
};
export default RegisterUltiProApi;