import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import PasswordInput from 'components/Input/PasswordInput';
import { getProviderFormLabel } from '../utils';
const SignInWorkdayCredential = ({
  isLoading,
  onSubmit,
  handleClick,
  provider,
  client,
  instructionsLink
}: RegisterImplementationProps) => {
  const instructions: JSX.Element = <p>
      To connect your Workday account, you must create a System User and a{' '}
      Custom Group Report Endpoint for <strong>{client.name}</strong>.
    </p>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} instructionsLink={instructionsLink} onSubmit={onSubmit} divider fieldNames={['instructionCheck', 'username', 'password', 'reportUrl']} render={({
    instructionCheck,
    username,
    password,
    reportUrl
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" id="username" />
              <PasswordInput value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} reset={{
          uri: new URL('https://www.workday.com/en-us/signin.html'),
          providerDisplayName: provider.displayName
        }} />
              <Input value={reportUrl.value} onChange={event => reportUrl.onChange(event.target.value)} invalid={reportUrl.invalid} label="Custom Report JSON Endpoint" id="reportUrl" />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Connect using the Workday API instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !username.value || !password.value || !reportUrl.value} type="submit" text="Connect" />
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInWorkdayCredential" data-sentry-source-file="SignInWorkdayCredential.tsx" />;
};
export default SignInWorkdayCredential;