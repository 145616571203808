import { useRef, useState } from 'react';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import NoticeMessage from '../../Messages/Notice';
import InstructionHeader from '../../../../components/Instruction/InstructionHeader';
import { AppLink } from 'components/Link/AppLink';
const RegisterGustoOAuth = ({
  client,
  onSubmit,
  setError,
  sandbox,
  handleClick,
  onMockOAuth
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [loading, setLoading] = useState(false);
  const externalWindowRef = useRef<Window | null>(null);

  // use any for type until Finch common types can be updated
  const gustoClientId = (client as any)?.gustoConfig?.clientId ?? '';
  const gustoRedirectUri = (client as any)?.gustoConfig?.redirectUri ?? '';
  const handleSubmit = async () => {
    setLoading(true);
    const didMockOAuth = await onMockOAuth();
    if (didMockOAuth) {
      setLoading(false);
      return;
    }
    const url = sandbox ? new URL(`https://api.gusto-demo.com/oauth/authorize`) : new URL(`https://api.gusto.com/oauth/authorize`);
    url.searchParams.set('client_id', gustoClientId);
    url.searchParams.set('redirect_uri', gustoRedirectUri);
    url.searchParams.set('response_type', 'code');
    externalWindowRef.current = window.open(url.toString(), 'popup', 'popup=true');
    const closeWindow = () => {
      setLoading(false);
      externalWindowRef?.current?.close();
    };
    if (!externalWindowRef.current || externalWindowRef.current.closed) return;
    const checkWindowInterval = setInterval(async () => {
      if (externalWindowRef?.current?.closed) {
        setLoading(false);
        clearInterval(checkWindowInterval);
      } else {
        try {
          const urlParams = new URLSearchParams(externalWindowRef?.current?.location.search);
          const code = urlParams.get('code');
          const error = urlParams.get('error');
          if (code) {
            closeWindow();
            await onSubmit({
              clientCode: code,
              providerRedirectUri: gustoRedirectUri
            });
          }
          if (error) {
            setError({
              message: error,
              status: null
            });
            closeWindow();
          }
        } catch (e) {
          // ignore dom exception errors
          if (e instanceof DOMException) {
            return;
          }
          setError({
            message: JSON.stringify(e),
            status: null
          });
          closeWindow();
        }
      }
    }, 500);
  };
  return <>
      <NoticeMessage data-sentry-element="NoticeMessage" data-sentry-source-file="RegisterGustoOAuth.tsx">
        Gusto authentication will continue in a new window. Please make sure to
        allow popups and finish authenticating through Gusto in the new window.
      </NoticeMessage>
      {sandbox === 'provider' && <InstructionHeader>
          <AppLink href="https://developer.tryfinch.com/docs/documentation/pzie4zvmok31z-provider-sandboxes#gusto" target="_blank" rel="noreferrer">
            Follow the setup instructions
          </AppLink>{' '}
          to create a Gusto developer account and demo companies. Once you have
          a demo company set up, connect it here.
        </InstructionHeader>}
      <MainContainer data-sentry-element="MainContainer" data-sentry-source-file="RegisterGustoOAuth.tsx" />
      <LoaderButton isLoading={loading} disabled={loading} onClick={handleSubmit} text="Connect" data-sentry-element="LoaderButton" data-sentry-source-file="RegisterGustoOAuth.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Use a Username and Password instead
        </SwitchImplementationButton>}
    </>;
};
export default withAuthorizeContext(RegisterGustoOAuth);