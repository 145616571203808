import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import { SubdomainInput } from 'components/Input/SubdomainInput';
import { apiTokenInstructions } from '../utils';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
const SignInNamelyApi = ({
  provider,
  client,
  isLoading,
  onSubmit,
  handleClick,
  instructionsLink
}: RegisterImplementationProps) => {
  const instructions = apiTokenInstructions(provider.displayName, client.name);
  return <SignInBase formLabel={`${provider.displayName} API Sign In Form`} instructions={instructions} instructionsLink={instructionsLink} divider onSubmit={onSubmit} fieldNames={['instructionCheck', 'subdomain', 'apiToken']} render={({
    instructionCheck,
    subdomain,
    apiToken
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <SubdomainInput label="Subdomain" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} domain="namely.com" />
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Token" />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Use an Email Address and Password instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !subdomain.value || !apiToken.value} type="submit" text="Connect" />
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInNamelyApi" data-sentry-source-file="SignInNamelyApi.tsx" />;
};
export default SignInNamelyApi;