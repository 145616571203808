import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { RegisterImplementationProps } from 'constants/types';
import PasswordInput from 'components/Input/PasswordInput';
import MainContainer from 'components/Container/MainContainer';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const RegisterPersonioApi = ({
  client,
  provider,
  isLoading,
  onSubmit,
  sandboxMode
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      After logging into your account, go to{' '}
      <strong>Settings &gt; API Credentials</strong>.
    </>, <>
      Click on the <strong>Generate new credential</strong> button.
    </>, <>
      Define the scope of access by marking the Employee <strong>read</strong>{' '}
      scope to be included, and select all employee readable attributes.
    </>];
  if (sandboxMode === 'provider') {
    instructions.unshift(<>
        <AppLink href="https://personio.com/partner-trial" target="_blank" rel="noreferrer">
          Sign up for a Personio partner trial
        </AppLink>
      </>);
  }
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterPersonioApi.tsx">
        To connect your Personio account, you must create an API token for{' '}
        <strong>{client.name}</strong>.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterPersonioApi.tsx" />
      <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['subdomain', 'providerClientId', 'providerClientSecret']} render={({
      subdomain,
      providerClientId,
      providerClientSecret
    }) => <>
            <MainContainer>
              <Input id="subdomain" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} label="Subdomain (from <subdomain>.personio.de)" />
              <Input id="providerClientId" value={providerClientId.value} onChange={event => providerClientId.onChange(event.target.value)} invalid={providerClientId.invalid} label="Client ID" />
              <PasswordInput id="providerClientSecret" value={providerClientSecret.value} onChange={event => providerClientSecret.onChange(event.target.value)} invalid={providerClientSecret.invalid} label="Client Secret" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit" text="Connect" />
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterPersonioApi.tsx" />
    </>;
};
export default RegisterPersonioApi;