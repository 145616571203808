/* eslint sort-keys: "error" */
import { Response as ClientResponse, ProviderConfig } from '@finch-api/common/dist/internal/connect/validate';
import styled from 'styled-components';
import { generateEmailWithRandomNumberAlias } from './utils';
import { detailsAndTroubleshooting } from './providerInstructionDetails';
import { AppLink } from 'components/Link/AppLink';
const providerInstructionUrlMap: Record<string, string> = {
  aap_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  abacus_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  absolute_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  accupay_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  ace_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  adams_keegan: 'https://support.tryfinch.com/hc/en-us/articles/26446409545748-Adams-Keegan-Add-an-administrator',
  adp_comprehensive: 'https://support.tryfinch.com/hc/en-us/articles/26375798015380-ADP-Comprehensive-Services-Add-an-administrator',
  adp_run: 'https://support.tryfinch.com/hc/en-us/articles/26205875256596-ADP-Run-Add-an-administrator',
  adp_totalsource: 'https://support.tryfinch.com/hc/en-us/articles/26206495567764-ADP-TotalSource-Add-an-administrator',
  adp_workforce_now: 'https://support.tryfinch.com/hc/en-us/articles/26207026920084-ADP-Workforce-Now-Add-an-administrator',
  advantage_payroll_services: 'https://support.tryfinch.com/hc/en-us/articles/26375971921556-Advantage-Payroll-Services-Add-an-administrator',
  affiliated_hr_payroll_services_evolution: 'https://support.tryfinch.com/hc/en-us/articles/28378845352596-Affiliated-HR-Payroll-Services-Evolution-Add-an-administrator',
  affiliated_hr_payroll_services_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  ahola: 'https://support.tryfinch.com/hc/en-us/articles/28485101735444-Ahola-Add-an-administrator',
  aps_payroll: 'https://support.tryfinch.com/hc/en-us/articles/26374027979668-APS-Payroll-Add-an-administrator',
  asset_hr: 'https://support.tryfinch.com/hc/en-us/articles/28485221031956-Asset-HR-Add-an-administrator',
  asure_software: 'https://support.tryfinch.com/hc/en-us/articles/28485317598356-Asure-Software-Add-an-administrator',
  balance_point: 'https://support.tryfinch.com/hc/en-us/articles/28485354219668-Balance-Point-Add-an-administrator',
  bamboo_hr: 'https://support.tryfinch.com/hc/en-us/articles/26376694531476-BambooHR-Add-a-Full-Admin',
  baron_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  benecare: 'https://support.tryfinch.com/hc/en-us/articles/28485433591700-Bene-Care-Add-an-administrator',
  big_fish_employer_services: 'https://support.tryfinch.com/hc/en-us/articles/28485456829076-Big-Fish-Employer-Services-Add-an-administrator',
  bizchecks_payroll: 'https://support.tryfinch.com/hc/en-us/articles/28485461897876-Bizchecks-Payroll-Add-an-administrator',
  bob: 'https://support.tryfinch.com/hc/en-us/articles/26207320426516-Bob-Add-a-Service-User',
  ceridian_dayforce: 'https://support.tryfinch.com/hc/en-us/articles/26375315334676-Ceridian-Dayforce-Add-an-administrator',
  charlie_hr: 'https://support.tryfinch.com/hc/en-us/articles/26377242125332-CharlieHR-Add-an-administrator',
  coastal_payroll: 'https://support.tryfinch.com/hc/en-us/articles/28485546601236-Coastal-Payroll-Add-an-administrator',
  collage: 'https://support.tryfinch.com/hc/en-us/articles/28485622464404-Collage-Add-an-administrator',
  commonwealth_payroll_hr: 'https://support.tryfinch.com/hc/en-us/articles/28485655749140-Commonwealth-Payroll-HR-Add-an-administrator',
  cpm_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  crescent_payroll_solutions: 'https://support.tryfinch.com/hc/en-us/articles/28485717259796-Crescent-Payroll-Solutions-Add-an-administrator',
  deel: 'https://support.tryfinch.com/hc/en-us/articles/26374316586516-Deel-Add-an-administrator',
  dominion_payroll: 'https://support.tryfinch.com/hc/en-us/articles/28485819684884-Dominion-Payroll-Add-an-administrator',
  employdrive: 'https://support.tryfinch.com/hc/en-us/articles/28485853791124-Employdrive-Add-an-administrator',
  goco: 'https://support.tryfinch.com/hc/en-us/articles/28486008747412-GoCo-Add-an-administrator',
  gtm_payroll_services_evolution: 'https://support.tryfinch.com/hc/en-us/articles/28486060742164-GTM-Payroll-Services-Evolution-Add-an-administrator',
  gtm_payroll_services_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  gusto: 'https://support.tryfinch.com/hc/en-us/articles/26377410565012-Gusto-Add-an-administrator',
  highflyer_hr: 'https://support.tryfinch.com/hc/en-us/articles/28486095066772-Highflyer-HR-Add-an-administrator',
  hubstaff: 'https://support.tryfinch.com/hc/en-us/articles/28486167282580-Hubstaff-Add-an-administrator',
  isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  justworks: 'https://support.tryfinch.com/hc/en-us/articles/26377528324244-Justworks-Add-an-administrator',
  keystone_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  mp: 'https://support.tryfinch.com/hc/en-us/articles/28486363388820-mp-Add-an-administrator',
  newtek: 'https://support.tryfinch.com/hc/en-us/articles/28486582603156-Newtek-Add-an-administrator',
  obsidianhr_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  onpay: 'https://support.tryfinch.com/hc/en-us/articles/26264163036436-OnPay-Add-an-Approver',
  paragon_payroll_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  patriot: 'https://support.tryfinch.com/hc/en-us/articles/26446094253204-Patriot-Add-an-administrator',
  pay_usa_inc: 'https://support.tryfinch.com/hc/en-us/articles/28486825865492-PayUSA-Add-an-administrator',
  paychex_flex: 'https://support.tryfinch.com/hc/en-us/articles/28486886711444-Paychex-Flex-Add-an-administrator',
  paycom: 'https://support.tryfinch.com/hc/en-us/articles/28486968742164-Paycom-Add-an-administrator',
  paycor: 'https://support.tryfinch.com/hc/en-us/articles/26341520116884-Paycor-Add-an-administrator',
  payday_workforce_solutions: 'https://support.tryfinch.com/hc/en-us/articles/28487076183700-Payday-Workforce-Solutions-Add-an-administrator',
  payfit: 'https://support.tryfinch.com/hc/en-us/articles/26378321970708-PayFit-Add-an-administrator',
  paylocity: 'https://support.tryfinch.com/hc/en-us/articles/26343235294612-Paylocity-Add-an-administrator',
  payroll_network: 'https://support.tryfinch.com/hc/en-us/articles/28487099567508-Payroll-Network-Add-an-administrator',
  payroll_plus_hcm_evolution: 'https://support.tryfinch.com/hc/en-us/articles/28487162989460-Payroll-Plus-HCM-Evolution-Add-an-administrator',
  payroll_plus_hcm_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  pcs_hcm: 'https://support.tryfinch.com/hc/en-us/articles/28487212924692-PCS-HCM-Add-an-administrator',
  platinum_group: 'https://support.tryfinch.com/hc/en-us/articles/28487236154004-Platinum-Group-Add-an-administrator',
  primepay: 'https://support.tryfinch.com/hc/en-us/articles/28487255444372-PrimePay-Add-an-administrator',
  quickbooks: 'https://support.tryfinch.com/hc/en-us/articles/26378785563796-Quickbooks-Add-an-accountant-or-admin',
  runforpartners: 'https://support.tryfinch.com/hc/en-us/articles/28487371693204-ADP-Run-for-Partners-Add-an-administrator',
  sage_hr: 'https://support.tryfinch.com/hc/en-us/articles/26379055666708-Sage-HR-Add-an-administrator',
  sage_payroll: 'https://support.tryfinch.com/hc/en-us/articles/28487416005396-Sage-Payroll-Add-an-administrator',
  savant_hcm_evolution: 'https://support.tryfinch.com/hc/en-us/articles/28487564060820-Savant-HCM-Evolution-Add-an-administrator',
  sequoia_one: 'https://support.tryfinch.com/hc/en-us/articles/28487660645012-Sequoia-One-Add-an-administrator',
  square_payroll: 'https://support.tryfinch.com/hc/en-us/articles/28492550640404-Square-Payroll-Add-an-administrator',
  strategic_payroll_solutions_isolved: 'https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator',
  successfactors: 'https://support.tryfinch.com/hc/en-us/articles/26380126137108-SAP-SuccessFactors-Add-an-administrator',
  sync_hr: 'https://support.tryfinch.com/hc/en-us/articles/28492886604052-SyncHR-Add-an-administrator',
  the_payroll_company: 'https://support.tryfinch.com/hc/en-us/articles/28493203077908-The-Payroll-Company-Add-an-administrator',
  toast_payroll: 'https://support.tryfinch.com/hc/en-us/articles/26375575934740-Toast-Payroll-Add-an-administrator',
  ukg_ready: 'https://support.tryfinch.com/hc/en-us/articles/26343475566356-UKG-Ready-Add-an-administrator',
  ulti_pro: 'https://support.tryfinch.com/hc/en-us/articles/26344059254420-UKG-Pro-Add-an-administrator',
  viewpoint_hr_management_spectrum: 'https://support.tryfinch.com/hc/en-us/articles/28493384465684-Viewpoint-HR-Management-Spectrum-Add-an-administrator',
  viewpoint_hr_management_vista: 'https://support.tryfinch.com/hc/en-us/articles/28493427652244-Viewpoint-HR-Management-Vista-Add-an-administrator',
  wagepoint: 'https://support.tryfinch.com/hc/en-us/articles/28493571120404-Wagepoint-Add-an-administrator',
  wave: 'https://support.tryfinch.com/hc/en-us/articles/26343623670676-Wave-Add-an-administrator',
  web_hr: 'https://support.tryfinch.com/hc/en-us/articles/26381776104468-WebHR-Add-an-administrator',
  whirks: 'https://support.tryfinch.com/hc/en-us/articles/28493673987476-Whirks-Add-an-administrator',
  workday: 'https://support.tryfinch.com/hc/en-us/articles/26242318065684-Workday-Add-an-Integration-System-User',
  wurk: 'https://support.tryfinch.com/hc/en-us/articles/28493775055892-wurk-Add-an-administrator',
  zenefits: 'https://support.tryfinch.com/hc/en-us/articles/26384668371092-Zenefits-Add-an-administrator',
  zoho_payroll: 'https://support.tryfinch.com/hc/en-us/articles/26385396415508-Zoho-Payroll-Add-an-administrator'
};
const instructionsUrl = (payrollProviderId: string) => {
  if (providerInstructionUrlMap[payrollProviderId]) {
    return providerInstructionUrlMap[payrollProviderId];
  }
  return 'https://support.tryfinch.com/hc/en-us/articles/26445991394068-How-to-add-an-admin-for-providers-that-require-help-from-a-representative';
};
export const updatedInstructions = (payrollProvider: ProviderConfig, client: ClientResponse, details: InstructionValues) => {
  const article = instructionsUrl(payrollProvider.id);
  const isXeroUSProvider = payrollProvider.id === 'xero_us';
  const preamble = <>
      To connect your {payrollProvider.displayName} account, you must add{' '}
      <b>{client.name}</b> as an admin to your payroll system.
      <br />
      <br />
    </>;
  const instructions = isXeroUSProvider ? <>
      Xero outsources all US payroll to Gusto. Please click{' '}
      <strong>Redirect</strong> to sign in with Gusto.
    </> : <>
      Follow{' '}
      <AppLink showInFrame target="_blank" href={article} rel="noopener noreferrer">
        Finch’s instructions
      </AppLink>{' '}
      to complete these steps and then come back here to continue connecting.
    </>;
  const instructionDetails = detailsAndTroubleshooting(payrollProvider.id, details);
  return <p data-sentry-component="updatedInstructions" data-sentry-source-file="instructions.tsx">
      {preamble}
      {instructions}
      {instructionDetails}
    </p>;
};
const StyledEmailAddress = styled.span`
  background-color: #fcf1c5;
`;
type InstructionValues = {
  username: string;
  accountantEmail?: string;
  userId: string;
  ssn?: string;
  phoneNumber: string;
};
type LabeledInstructionValue = [keyof InstructionValues, string];
type ExternalInstructions = Partial<InstructionValues> & {
  article: string;
  reveal: LabeledInstructionValue[];
};
type InstructionsFn = (args: InstructionValues) => JSX.Element[];
const steps = ({
  article,
  reveal: labels,
  ...values
}: ExternalInstructions) => [<>
    Please follow the instructions{' '}
    <AppLink showInFrame target="_blank" href={article} rel="noopener noreferrer">
      HERE.
    </AppLink>{' '}
    using the information below:
  </>, <>
    {labels.map(([key, label], index) => <div key={index}>
        {label}: <strong>{values[key]}</strong>
      </div>)}
  </>];
const generic: InstructionsFn = args => steps({
  ...args,
  article: 'https://support.tryfinch.com/hc/en-us/articles/26445991394068-How-to-add-an-admin-for-providers-that-require-help-from-a-representative',
  reveal: [['username', 'Username'], ['accountantEmail', 'Email']]
});
const specific: (href: string, ...labels: LabeledInstructionValue[]) => InstructionsFn = (href, ...labels) => args => steps({
  ...args,
  article: href,
  reveal: labels
});
const instructions: {
  [payrollProviderId: string]: InstructionsFn;
} = {
  aap_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  abacus_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  absolute_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  accountantsworld: generic,
  accupay_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  ace_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  adams_keegan: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26446409545748-Adams-Keegan-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  adp_comprehensive: specific('https://support.tryfinch.com/hc/en-us/articles/26375798015380-ADP-Comprehensive-Services-Add-an-administrator', ['accountantEmail', 'Email']),
  adp_enterprise: generic,
  adp_run: ({
    userId,
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26205875256596-ADP-Run-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        User ID: <strong>{userId}</strong>
      </div>
      <div>
        First Name: <strong>{username}</strong>
      </div>
      <div>
        Last Name: <strong>Support</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
      <div>
        Role: <strong>Owner</strong>.
      </div>
    </>],
  adp_teampay: generic,
  adp_totalsource: specific('https://support.tryfinch.com/hc/en-us/articles/26206495567764-ADP-TotalSource-Add-an-administrator', ['accountantEmail', 'Email']),
  adp_vantage: generic,
  adp_workforce_now: ({
    userId,
    accountantEmail
  }) => [<>
      First, make sure you are on ADP Workforce Now. If you are using a PEO
      (professional employer organization), you are using ADP TotalSource -
      please make your way to those instructions instead.
    </>, <>
      Follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26207026920084-ADP-Workforce-Now-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>First Name:</strong> {userId}
      </div>
      <div>
        <strong>Last Name:</strong> Support
      </div>
      <div>
        <strong>Email Address:</strong>{' '}
        <StyledEmailAddress>{accountantEmail}</StyledEmailAddress>
      </div>
    </>],
  adpnextgen: generic,
  advantage_payroll_services: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375971921556-Advantage-Payroll-Services-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>Email Address:</strong>{' '}
        <StyledEmailAddress>{accountantEmail}</StyledEmailAddress>
      </div>
    </>, <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>],
  affiliated_hr_payroll_services_evolution: specific('https://support.tryfinch.com/hc/en-us/articles/28378845352596-Affiliated-HR-Payroll-Services-Evolution-Add-an-administrator', ['accountantEmail', 'Username'], ['username', 'First Name']),
  affiliated_hr_payroll_services_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  ahola: specific('https://support.tryfinch.com/hc/en-us/articles/28485101735444-Ahola-Add-an-administrator', ['accountantEmail', 'Email'], ['username', 'Username']),
  alcott_hr: generic,
  alight: generic,
  alliance: generic,
  alphastaff: generic,
  amplify_hr: generic,
  apdata: generic,
  apex: generic,
  aps_payroll: specific('https://support.tryfinch.com/hc/en-us/articles/26374027979668-APS-Payroll-Add-an-administrator', ['username', 'First Name'], ['username', 'User Name'], ['accountantEmail', 'Email']),
  armhr: generic,
  aspenhr: generic,
  asset_hr: specific('https://support.tryfinch.com/hc/en-us/articles/28485221031956-Asset-HR-Add-an-administrator', ['accountantEmail', 'Email'], ['username', 'Username']),
  asure_software: specific('https://support.tryfinch.com/hc/en-us/articles/28485317598356-Asure-Software-Add-an-administrator', ['accountantEmail', 'Username'], ['username', 'First Name']),
  balance_point: specific('https://support.tryfinch.com/hc/en-us/articles/28485354219668-Balance-Point-Add-an-administrator', ['accountantEmail', 'Email'], ['username', 'Username']),
  bambee: generic,
  bamboo_hr: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26376694531476-BambooHR-Add-a-Full-Admin" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
    </>, <>
      CC <strong>{username}</strong> using the following email address:{' '}
      <strong>{accountantEmail}</strong>.
    </>],
  baron_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  basic: generic,
  bayzat: generic,
  bbcs: generic,
  bbsi: generic,
  bcn_services: generic,
  benecare: specific('https://support.tryfinch.com/hc/en-us/articles/28485433591700-Bene-Care-Add-an-administrator', ['accountantEmail', 'Email'], ['username', 'Username']),
  big_fish_employer_services: specific('https://support.tryfinch.com/hc/en-us/articles/28485456829076-Big-Fish-Employer-Services-Add-an-administrator', ['accountantEmail', 'Email'], ['username', 'Username']),
  bizchecks_payroll: specific('https://support.tryfinch.com/hc/en-us/articles/28485461897876-Bizchecks-Payroll-Add-an-administrator', ['accountantEmail', 'Email'], ['username', 'Username']),
  bob: specific('https://support.tryfinch.com/hc/en-us/articles/26207320426516-Bob-Add-a-Service-User', ['username', 'First Name'], ['accountantEmail', 'Email']),
  business_online_payroll: generic,
  businesssolver: generic,
  c2: generic,
  ceridian_dayforce: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375315334676-Ceridian-Dayforce-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        User Name/Email: <strong>{accountantEmail}</strong>.
      </div>
      <div>
        Name: <strong>{username}</strong>
      </div>
    </>],
  ceridian_powerpay: generic,
  cezannehr: generic,
  charlie_hr: ({
    userId,
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26377242125332-CharlieHR-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>First Name:</strong> {userId}
      </div>
      <div>
        <strong>Last Name:</strong> Support
      </div>
      <div>
        <strong>Email Address:</strong>{' '}
        <StyledEmailAddress>{accountantEmail}</StyledEmailAddress>
      </div>
    </>, <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Admin. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>],
  checkwriters: generic,
  clickup: generic,
  cmic: generic,
  coadvantage: generic,
  coastal_payroll: specific('https://support.tryfinch.com/hc/en-us/articles/28485546601236-Coastal-Payroll-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  cognos_hr: generic,
  collage: specific('https://support.tryfinch.com/hc/en-us/articles/28485622464404-Collage-Add-an-administrator', ['username', 'Username'], ['accountantEmail', 'Email Address']),
  commonwealth_payroll_hr: specific('https://support.tryfinch.com/hc/en-us/articles/28485655749140-Commonwealth-Payroll-HR-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  connectpay: generic,
  corporatepayroll: generic,
  costaff: generic,
  cpm_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  creative_business_resources: generic,
  crescent_payroll_solutions: specific('https://support.tryfinch.com/hc/en-us/articles/28485717259796-Crescent-Payroll-Solutions-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  criterion: generic,
  csc_paymaster: generic,
  darwinbox: generic,
  decent: generic,
  deel: specific('https://support.tryfinch.com/hc/en-us/articles/26374316586516-Deel-Add-an-administrator', ['userId', 'First Name'], ['accountantEmail', 'Email Address']),
  deltek: generic,
  deluxe: generic,
  dm_payroll_solutions: generic,
  dominion_payroll: specific('https://support.tryfinch.com/hc/en-us/articles/28485819684884-Dominion-Payroll-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  doyle_hcm: generic,
  ecms: generic,
  eddyhr: generic,
  elite_payroll: generic,
  emcentrix: generic,
  emplicity: generic,
  employdrive: specific('https://support.tryfinch.com/hc/en-us/articles/28485853791124-Employdrive-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  employer_flexible: generic,
  employmenthero: generic,
  empower_hr: generic,
  engage_peo: generic,
  esc: generic,
  everee: generic,
  evolution_payroll_services: generic,
  excel_resources: generic,
  extensis_hr: generic,
  flock: generic,
  freshteam: generic,
  freshworks: generic,
  fullstack_peo: generic,
  gigwage: generic,
  gna_partners: generic,
  goco: specific('https://support.tryfinch.com/hc/en-us/articles/28486008747412-GoCo-Add-an-administrator', ['username', 'First Name'], ['accountantEmail', 'Email']),
  greenleaf: generic,
  greyt_hr: generic,
  gtm_payroll_services_evolution: specific('https://support.tryfinch.com/hc/en-us/articles/28486060742164-GTM-Payroll-Services-Evolution-Add-an-administrator', ['accountantEmail', 'Username'], ['username', 'First Name']),
  gtm_payroll_services_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  gusto: ({
    userId,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26377410565012-Gusto-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>Name:</strong> {userId}
      </div>
      <div>
        <strong>Email:</strong> {accountantEmail}
      </div>
    </>],
  heartland: generic,
  helpside: generic,
  highflyer_hr: specific('https://support.tryfinch.com/hc/en-us/articles/28486095066772-Highflyer-HR-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  homebase: generic,
  horizonpayroll: generic,
  horizons: generic,
  hrcloud: generic,
  hro: generic,
  hrone: generic,
  hubstaff: specific('https://support.tryfinch.com/hc/en-us/articles/28486167282580-Hubstaff-Add-an-administrator', ['accountantEmail', 'Email']),
  humaans: generic,
  humi: generic,
  hybrid: generic,
  infinipay: generic,
  infiniti_hr: generic,
  infor: generic,
  insperity: specific(
  // NOTE: only has username/password—may not be applicable
  'https://support.tryfinch.com/hc/en-us/articles/28486249986580/preview/eyJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjg0ODYyNDk5ODY1ODAsImV4cCI6MTcyMTA2MzAxOX0.F-GQKOGe5vqeFInjLJruMajIWSfJxVWtOfC2hIQlW-s', ['username', 'Username'], ['accountantEmail', 'Email Address']),
  iris_hr: generic,
  isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  justworks: ({
    userId,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26377528324244-Justworks-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>First and last name:</strong> {userId}
      </div>
      <div>
        <strong>Email:</strong> {accountantEmail}
      </div>
    </>],
  keka: generic,
  kenjo: generic,
  keystone_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  miter: generic,
  mp: specific('https://support.tryfinch.com/hc/en-us/articles/28486363388820-mp-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  mpay: generic,
  multiplier: generic,
  namely: specific(
  // NOTE: only has api & not showing in connect—may not be applicable
  'https://support.tryfinch.com/hc/en-us/articles/28486380702356/preview/eyJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjg0ODYzODA3MDIzNTYsImV4cCI6MTcyMTA2MzE1OH0.yg4XxQJsUU1wvQxw5InCWuQhRnOcMpLDr3vfr078CJM', ['accountantEmail', 'Email Address']),
  natural_hr: generic,
  netchex: generic,
  newtek: specific('https://support.tryfinch.com/hc/en-us/articles/28486582603156-Newtek-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  nextep: generic,
  north_bay_payroll: generic,
  nvision: generic,
  oasis: generic,
  obsidianhr_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  odoo: generic,
  omni_hr: generic,
  onepointhcm: generic,
  onesource_payroll: generic,
  onpay: ({
    userId,
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26264163036436-OnPay-Add-an-Approver" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>First Name:</strong> {userId}
      </div>
      <div>
        <strong>Last Name:</strong> Support
      </div>
      <div>
        <strong>Work Email:</strong> {accountantEmail}
      </div>
    </>, <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>],
  opolis: generic,
  oracle_fusion: generic,
  oracle_peoplesoft: generic,
  panther: generic,
  paragon_payroll_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  patriot: specific('https://support.tryfinch.com/hc/en-us/articles/26446094253204-Patriot-Add-an-administrator', ['accountantEmail', 'Email']),
  pay_usa_inc: specific('https://support.tryfinch.com/hc/en-us/articles/28486825865492-PayUSA-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  paychex_flex: specific('https://support.tryfinch.com/hc/en-us/articles/28486886711444-Paychex-Flex-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  paycom: specific('https://support.tryfinch.com/hc/en-us/articles/28486968742164-Paycom-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  paycor: ({
    userId,
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26341520116884-Paycor-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>First Name:</strong> {userId}
      </div>
      <div>
        <strong>Last Name:</strong> Support
      </div>
      <div>
        <strong>Work Email:</strong> {accountantEmail}
      </div>
      <div>
        <strong>Work Number:</strong> 917-717-3974
      </div>
      <div>
        <strong>Contact Type:</strong> Administrator or Primary
      </div>
    </>, <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>],
  payday_workforce_solutions: specific('https://support.tryfinch.com/hc/en-us/articles/28487076183700-Payday-Workforce-Solutions-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  payentry: generic,
  payfit: ({
    userId,
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26378321970708-PayFit-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>Email Address:</strong>{' '}
        <StyledEmailAddress>{accountantEmail}</StyledEmailAddress>
      </div>
      <div>
        <strong>First Name:</strong> {userId}
      </div>
      <div>
        <strong>Last Name:</strong> Support
      </div>
      <div>
        <strong>Role:</strong> Administrator
      </div>
    </>, <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>],
  payfluence: generic,
  paylocity: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26343235294612-Paylocity-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        First Name: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  paynw: generic,
  paypro_hcs_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  payroll_for_construction: generic,
  payroll_network: specific('https://support.tryfinch.com/hc/en-us/articles/28487099567508-Payroll-Network-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  payroll_office_of_america: generic,
  payroll_plus_hcm_evolution: specific('https://support.tryfinch.com/hc/en-us/articles/28487162989460-Payroll-Plus-HCM-Evolution-Add-an-administrator', ['accountantEmail', 'Username'], ['username', 'First Name']),
  payroll_plus_hcm_isolved: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  payroll_resource_group: generic,
  payroll_solutions: generic,
  payrollontime: generic,
  paytime: generic,
  pcs_hcm: specific('https://support.tryfinch.com/hc/en-us/articles/28487212924692-PCS-HCM-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  penta: generic,
  people_lease: generic,
  peoplecore: generic,
  peopleforce: generic,
  peoplehum: generic,
  personio: generic,
  platinum_group: specific('https://support.tryfinch.com/hc/en-us/articles/28487236154004-Platinum-Group-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  premiernow: generic,
  prestige_peo: generic,
  primepay: specific('https://support.tryfinch.com/hc/en-us/articles/28487255444372-PrimePay-Add-an-administrator', ['username', 'First Name'], ['accountantEmail', 'Email Address']),
  prism_hr: generic,
  proliant: generic,
  propel_hr: generic,
  prosoftware: generic,
  proxus_hr: generic,
  quality_payroll_benefits: generic,
  questco: generic,
  quickbooks: ({
    userId,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26378785563796-Quickbooks-Add-an-accountant-or-admin" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>First and last name:</strong> {userId}
      </div>
      <div>
        <strong>Email:</strong> {accountantEmail}
      </div>
    </>],
  quickhr: generic,
  remote: generic,
  remotepass: generic,
  resourcing_edge: generic,
  restaurant365: generic,
  rippling: specific(
  // NOTE: may not be applicable anymore due to partnership & oauth integration reqs
  'https://support.tryfinch.com/hc/en-us/articles/28487348657300/preview/eyJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjg0ODczNDg2NTczMDAsImV4cCI6MTcyMTA2NDM4OX0.cCqafDjFuvBb8j68v-px0z2hYGyn6Cf4aHkZjdD5n38', ['username', 'Name'], ['accountantEmail', 'Email']),
  rmi_peo: generic,
  runforpartners: specific('https://support.tryfinch.com/hc/en-us/articles/28487371693204-ADP-Run-for-Partners-Add-an-administrator', ['userId', 'User ID'], ['accountantEmail', 'Email']),
  sage100: generic,
  sage_hr: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26379055666708-Sage-HR-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
    </>, <>
      CC <strong>{username}</strong> using the following email address:{' '}
      <strong>{accountantEmail}</strong>.
    </>],
  sage_payroll: specific('https://support.tryfinch.com/hc/en-us/articles/28487416005396-Sage-Payroll-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  sapling: specific(
  // NOTE: only has api—may not be applicable
  'https://support.tryfinch.com/hc/en-us/articles/28487443388436/preview/eyJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjg0ODc0NDMzODg0MzYsImV4cCI6MTcyMTA2NDU5N30.u01LFMX-K51ZKkjDD1e39pmhST1ptJlJhVJNmZZ4NbU', ['userId', 'Username'], ['accountantEmail', 'Email'], ['username', 'Administrator']),
  savant_hcm_evolution: specific('https://support.tryfinch.com/hc/en-us/articles/28487564060820-Savant-HCM-Evolution-Add-an-administrator', ['accountantEmail', 'Username'], ['username', 'First Name']),
  sequoia_one: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/28487660645012-Sequoia-One-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Name: <strong>{username}</strong>
      </div>
      <div>
        Email:{' '}
        <strong>{generateEmailWithRandomNumberAlias(accountantEmail)}</strong>.
      </div>
    </>],
  sesame: generic,
  sheakley: generic,
  simploy: generic,
  skuad: generic,
  solution_services: generic,
  sourceone: generic,
  southeastpersonnel: generic,
  square_payroll: specific('https://support.tryfinch.com/hc/en-us/articles/28492550640404-Square-Payroll-Add-an-administrator', ['username', 'First Name'], ['accountantEmail', 'Email Address'], ['phoneNumber', 'Phone Number']),
  strategic_payroll_solutions_isolved: ({
    accountantEmail,
    username
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375418165908-isolved-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Username: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  strongpay: generic,
  successfactors: ({
    userId,
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26380126137108-SAP-SuccessFactors-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>Group Name:</strong> {userId}
      </div>
      <div>
        <strong>User ID:</strong> {userId}
      </div>
    </>, <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>],
  suite_people: generic,
  sunfish: generic,
  surepayroll: generic,
  sync_hr: specific('https://support.tryfinch.com/hc/en-us/articles/28492886604052-SyncHR-Add-an-administrator', ['username', 'First Name'], ['accountantEmail', 'Email'], ['phoneNumber', 'Phone Number']),
  synchronyhr: generic,
  talenta: generic,
  tandemhr: generic,
  teamworks: generic,
  the_payroll_company: specific('https://support.tryfinch.com/hc/en-us/articles/28493203077908-The-Payroll-Company-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  thread_hcm: generic,
  toast_payroll: ({
    userId,
    accountantEmail
  }) => [<>
      Follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26375575934740-Toast-Payroll-Add-an-administrator" rel="noopener noreferrer">
        HERE
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>First Name:</strong> {userId}
      </div>
      <div>
        <strong>Last Name:</strong> Support
      </div>
      <div>
        <strong>Email Address:</strong>{' '}
        <StyledEmailAddress>{accountantEmail}</StyledEmailAddress>
      </div>
    </>],
  trakstar: generic,
  trinet: specific(
  // NOTE: only has api—may not be applicable
  'https://support.tryfinch.com/hc/en-us/articles/28493303335444/preview/eyJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjg0OTMzMDMzMzU0NDQsImV4cCI6MTcyMTg0NDM5MH0.Urq65Fl929BsX6UQkJB9qN13Vs-h22dFN5eRVGIcROE', ['username', 'First Name'], ['ssn', 'SSN'], ['accountantEmail', 'Email'], ['phoneNumber', 'Phone']),
  trion: generic,
  ukg_ready: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26343475566356-UKG-Ready-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        First Name: <strong>{username}</strong>
      </div>
      <div>
        Email Address: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  ulti_pro: ({
    userId,
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26344059254420-UKG-Pro-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        Name: <strong>{username}</strong>
      </div>
      <div>
        Email Address: <strong>{accountantEmail}</strong>.
      </div>
      <div>
        User Name: <strong>{userId}</strong>.
      </div>
    </>],
  uzio: generic,
  velocity_global: generic,
  vensure_hr: generic,
  venture_workforce: generic,
  vfficient: generic,
  viewpoint_hr_management_spectrum: specific('https://support.tryfinch.com/hc/en-us/articles/28493384465684-Viewpoint-HR-Management-Spectrum-Add-an-administrator', ['username', 'First Name'], ['accountantEmail', 'Email']),
  viewpoint_hr_management_vista: specific('https://support.tryfinch.com/hc/en-us/articles/28493427652244-Viewpoint-HR-Management-Vista-Add-an-administrator', ['username', 'Name']),
  viventium: generic,
  wagepoint: specific('https://support.tryfinch.com/hc/en-us/articles/28493571120404-Wagepoint-Add-an-administrator', ['username', 'First Name'], ['accountantEmail', 'Email']),
  warp: generic,
  wave: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26343623670676-Wave-Add-an-administrator" rel="noopener noreferrer">
        HERE.
      </AppLink>{' '}
      using the information below:
    </>, <>
      <div>
        First Name: <strong>{username}</strong>
      </div>
      <div>
        Email: <strong>{accountantEmail}</strong>.
      </div>
    </>],
  web_hr: ({
    userId,
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26381776104468-WebHR-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>User Name:</strong> {userId}
      </div>
      <div>
        <strong>Email Address:</strong>{' '}
        <StyledEmailAddress>{accountantEmail}</StyledEmailAddress>
      </div>
      <div>
        <strong>First Name:</strong> {userId}
      </div>
      <div>
        <strong>Last Name:</strong> Admin
      </div>
    </>, <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>],
  whirks: specific('https://support.tryfinch.com/hc/en-us/articles/28493673987476-Whirks-Add-an-administrator', ['accountantEmail', 'Email Address'], ['username', 'Username']),
  wolke: generic,
  workday: ({
    userId,
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26242318065684-Workday-Add-an-Integration-System-User" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>User Name:</strong> {userId}
      </div>
      <div>
        <strong>Email:</strong> {accountantEmail}
      </div>
      <div>
        <strong>Security Group Name:</strong> {userId}
      </div>
    </>, <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>],
  workforce_junction: generic,
  worklio: generic,
  workplace_hcm: generic,
  workzoom: generic,
  wurk: specific('https://support.tryfinch.com/hc/en-us/articles/28493775055892-wurk-Add-an-administrator', ['username', 'Username'], ['username', 'First Name'], ['accountantEmail', 'Primary Email']),
  xenium_hr: generic,
  xero: generic,
  xero_us: () => [<>
      Xero outsources all US payroll to Gusto. Please click{' '}
      <strong>Redirect</strong> to sign in with Gusto.
    </>],
  zenefits: ({
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26384668371092-Zenefits-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
    </>, <>
      CC <strong>{username}</strong> using the following email address:{' '}
      <strong>{accountantEmail}</strong>.
    </>],
  zimyo: generic,
  zoho_payroll: ({
    userId,
    username,
    accountantEmail
  }) => [<>
      Please follow the instructions{' '}
      <AppLink showInFrame target="_blank" href="https://support.tryfinch.com/hc/en-us/articles/26385396415508-Zoho-Payroll-Add-an-administrator" rel="noopener noreferrer">
        here
      </AppLink>{' '}
      using the information below:
      <div>
        <strong>Name:</strong> {userId}
      </div>
      <div>
        <strong>Email Address:</strong>{' '}
        <StyledEmailAddress>{accountantEmail}</StyledEmailAddress>
      </div>
    </>, <>
      If you are not able to follow the instructions above, please contact your
      account representative and share that you would like to add{' '}
      <strong>{username}</strong> as an External Administrator. CC{' '}
      <strong>{accountantEmail}</strong> so we can see all communications and
      help troubleshoot.
    </>]
};
export const instructionsForPayrollProviderId = (payrollProviderId: string): InstructionsFn => {
  if (!(payrollProviderId in instructions)) throw new Error(`no instructions for provider ${payrollProviderId}`);
  return instructions[payrollProviderId];
};
export default instructions;