import { create } from 'zustand';

export const useAuthFailureCount = create<{
  authFailureCounts: Record<string, number>;
  setAuthFailureCounts: (authFailureCounts: Record<string, number>) => void;
}>((set) => ({
  authFailureCounts: {},
  setAuthFailureCounts: (authFailureCounts: Record<string, number>) =>
    set({ authFailureCounts }),
}));
