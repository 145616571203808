import { Response as ClientResponse, ProviderConfig, ImplementationDetail } from '@finch-api/common/dist/internal/connect/validate';
import LoaderButton from 'components/Button/LoaderButton';
import { updatedInstructions } from './instructions';
import { ConnectError, StateRedirect } from '../types';
import SignInBase from '../SignIn/SignInBase';
import MainContainer from '../SignIn/SignInMainContainer';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import { ProviderAppShell } from '../ProviderAppShell';
export const FINCH_DEFAULT_PHONE = '(407) 741-3626';
const SignInManualInstruction = ({
  client,
  payrollProvider,
  contactEmailInContext,
  companyNameInContext,
  onSubmit,
  onPrevStep,
  handleStateRedirect,
  providerImplementation,
  error,
  setError
}: {
  client: ClientResponse;
  payrollProvider: ProviderConfig;
  contactEmailInContext: string | undefined;
  companyNameInContext: string | undefined;
  onSubmit: () => Promise<void>;
  onPrevStep: () => void;
  handleStateRedirect: (redirect: StateRedirect) => void;
  providerImplementation: ImplementationDetail;
  error: ConnectError | null;
  setError: (error: ConnectError | null) => void;
}) => {
  const isXeroUSProvider = payrollProvider.id === 'xero_us';
  const details = {
    accountantEmail: client?.manualConfig?.email || payrollProvider?.defaultAccountantEmail,
    username: client?.manualConfig?.name || client?.name,
    userId: client?.manualConfig?.userId || client?.manualConfig?.name || client?.name,
    ssn: client?.manualConfig?.ssn,
    phoneNumber: client?.manualConfig?.phoneNumber || FINCH_DEFAULT_PHONE
  };
  const instructions = updatedInstructions(payrollProvider, client, details);
  const onRedirect = () => {
    handleStateRedirect({
      next: 'SIGN_IN',
      nextContext: {
        redirect: true,
        payrollProviderId: 'gusto'
      }
    });
  };
  return <>
      <ProviderAppShell provider={payrollProvider} error={error} providerImplementation={providerImplementation} setError={setError} headerText={`Connect your ${payrollProvider.displayName} account`} goBack={!(companyNameInContext && contactEmailInContext) ? onPrevStep : undefined} data-sentry-element="ProviderAppShell" data-sentry-source-file="SignInManualInstruction.tsx">
        <SignInBase formLabel="Manual Sign In Form" spacing="md" instructions={instructions} divider fieldNames={['instructionCheck']} onSubmit={isXeroUSProvider ? onRedirect : onSubmit} render={({
        instructionCheck
      }) => {
        return <>
                <MainContainer useNewDesign>
                  {!isXeroUSProvider && <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />}
                </MainContainer>
                <LoaderButton type="submit" disabled={!instructionCheck.value} text={isXeroUSProvider ? 'Redirect' : 'Complete'} />
              </>;
      }} data-sentry-element="SignInBase" data-sentry-source-file="SignInManualInstruction.tsx" />
      </ProviderAppShell>
    </>;
};
export default SignInManualInstruction;