import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import { getProviderFormLabel } from '../utils';
const SignInPaycomCredential = ({
  isLoading,
  onSubmit,
  client,
  handleClick,
  provider
}: RegisterImplementationProps) => {
  const instructions: JSX.Element = <p>
      <strong>{client.name}</strong> will be added as a third-party
      administrator to retrieve data.
      <br /> <br />
      Your Client Code is the 5-digit ID associated with your account. It is
      located on the homepage to the left of ALLDEPTS in the white menu bar.
    </p>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} onSubmit={onSubmit} fieldNames={['clientCode', 'username', 'password']} render={({
    clientCode,
    username,
    password
  }) => {
    return <>
            <MainContainer useNewDesign>
              <Input value={clientCode.value} onChange={event => clientCode.onChange(event.target.value)} invalid={clientCode.invalid} characterLimit={5} id="clientCode" label="Client Code" />
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" id="username" />
              {/* Password reset is handled by company's HR/Payroll team
               per Paycom's docs: https://www.paycom.com/contact-us/tips/
               (see "How can I change my username or password?" ) so there's
               not really a link to be added here */}
              <PasswordInput value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Use an API Token instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={isLoading} disabled={isLoading || !clientCode.value || !username.value || !password.value} type="submit" text="Connect" />
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInPaycomCredential" data-sentry-source-file="SignInPaycomCredential.tsx" />;
};
export default SignInPaycomCredential;