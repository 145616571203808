import { create } from 'zustand';

export const useSuccessPage = create<{
  successPageRedirectUrl: string | null;
  setSuccessPageRedirectUrl: (redirectUrl: string | null) => void;
}>((set) => ({
  successPageRedirectUrl: null,
  setSuccessPageRedirectUrl: (redirectUrl: string | null) =>
    set({ successPageRedirectUrl: redirectUrl }),
}));
