import { ButtonProps, Center, HStack, Icon, IconButton, Spinner, Stack, StackSeparator } from '@chakra-ui/react';
import { usePopOutInstructionsLink } from 'store/pop-out-instructions';
import { CloseInstructionsIcon } from 'components/Icons/CloseInstructionsIcon';
import { Button } from 'components/ui/button';
import { CopyIcon } from 'components/Icons/CopyIcon';
import { NewTabIcon } from 'components/Icons/NewTabIcon';
import COLORS from 'constants/colors';
import { Tooltip } from 'components/ui/tooltip';
import { useInstructionsHtml } from '../../../services/instructions-html';
import { IsolatedContent } from './IsolatedContent';
import { useClipboard, useClipboardPermission } from 'hooks/use-clipboard';
const PlainButton = (props: ButtonProps & {
  href?: string | null;
  target?: string;
}) => <Button variant="plain" fontSize="13px" css={{
  '& :where(svg)': {
    boxSize: '16px'
  }
}} color={COLORS.GRAY.GRAY_600} _hover={{
  color: COLORS.BLACK
}} h="20px" p="0" {...props} data-sentry-element="Button" data-sentry-component="PlainButton" data-sentry-source-file="InstructionsPopout.tsx" />;
export const InstructionsPopout = () => {
  const {
    popOutInstructionsLink,
    setPopOutInstructionsLink
  } = usePopOutInstructionsLink();
  const {
    html,
    isPending,
    isFinchSupportLink
  } = useInstructionsHtml({
    instructionsLink: popOutInstructionsLink
  });
  const {
    copied,
    copy
  } = useClipboard(popOutInstructionsLink ?? '');
  const {
    data: clipboardPermission
  } = useClipboardPermission();
  return <Stack w="full" separator={<StackSeparator />} justify="flex-start" h="full" gap="0" data-sentry-element="Stack" data-sentry-component="InstructionsPopout" data-sentry-source-file="InstructionsPopout.tsx">
      <HStack justify="space-between" px="20px" py="16px" data-sentry-element="HStack" data-sentry-source-file="InstructionsPopout.tsx">
        <Tooltip content="Close instructions" openDelay={250} positioning={{
        placement: 'right-end'
      }} contentProps={{
        fontSize: '13px',
        borderRadius: '6px'
      }} data-sentry-element="Tooltip" data-sentry-source-file="InstructionsPopout.tsx">
          <IconButton boxSize="24px" minW="24px" variant="ghost" aria-label="Close Instructions" onClick={() => setPopOutInstructionsLink(null)} css={{
          '& :where(svg)': {
            boxSize: '17px'
          }
        }} color={COLORS.GRAY.GRAY_600} _hover={{
          color: COLORS.BLACK
        }} data-sentry-element="IconButton" data-sentry-source-file="InstructionsPopout.tsx">
            <CloseInstructionsIcon data-sentry-element="CloseInstructionsIcon" data-sentry-source-file="InstructionsPopout.tsx" />
          </IconButton>
        </Tooltip>
        <HStack gap="4" data-sentry-element="HStack" data-sentry-source-file="InstructionsPopout.tsx">
          {(clipboardPermission?.state === 'granted' || clipboardPermission?.state === 'prompt') && <PlainButton onClick={() => copy()} p="0">
              <Icon boxSize="16px">
                <CopyIcon />
              </Icon>
              {copied ? 'Copied' : 'Copy link to instructions'}
            </PlainButton>}
          <PlainButton as="a" href={popOutInstructionsLink} target="_blank" rel="noreferrer" data-sentry-element="PlainButton" data-sentry-source-file="InstructionsPopout.tsx">
            <Icon data-sentry-element="Icon" data-sentry-source-file="InstructionsPopout.tsx">
              <NewTabIcon data-sentry-element="NewTabIcon" data-sentry-source-file="InstructionsPopout.tsx" />
            </Icon>
            Open in new tab
          </PlainButton>
        </HStack>
      </HStack>
      <Center px="20px" h="full" pb="0" overflowY="auto" _scrollbar={{
      w: '6px'
    }} _scrollbarThumb={{
      bg: COLORS.GRAY.GRAY_400,
      borderRadius: '2px'
    }} _scrollbarTrack={{
      px: '10px',
      bg: COLORS.WHITE
    }} data-sentry-element="Center" data-sentry-source-file="InstructionsPopout.tsx">
        {isPending && <Spinner />}
        {html && <IsolatedContent html={html} renderInIframe={!isFinchSupportLink} />}
      </Center>
    </Stack>;
};