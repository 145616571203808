import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
export function SignInBobApi({
  client,
  isLoading,
  onSubmit,
  handleClick,
  sandboxMode,
  instructionsLink,
  provider
}: RegisterImplementationProps) {
  const instructions: JSX.Element = <>
      {sandboxMode === 'provider' ? <p>
          First make sure to{' '}
          <AppLink href="https://developer.tryfinch.com/docs/documentation/pzie4zvmok31z-provider-sandboxes#bob" target="_blank" rel="noreferrer">
            follow the setup instructions
          </AppLink>{' '}
          to get a test account for Bob.
        </p> : null}
      <p>
        To connect your HiBob account, you must create an API token for{' '}
        <strong>{client.name}</strong>.
      </p>
    </>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} instructionsLink={instructionsLink} divider onSubmit={onSubmit} fieldNames={['username', 'apiToken', 'instructionCheck']} render={({
    username,
    apiToken,
    instructionCheck
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Service User ID" />
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Token" />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Use an Email Address and Password instead
              </SwitchImplementationButton>}
            <LoaderButton text="Connect" isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !username.value || !apiToken.value} type="submit" />
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInBobApi" data-sentry-source-file="SignInBobApi.tsx" />;
}