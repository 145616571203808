import { useRef, useState } from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import LoaderButton from 'components/Button/LoaderButton';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { getProviderFormLabel } from '../utils';
const SignInGusto = ({
  client,
  sandbox,
  onSubmit,
  onMockOAuth,
  setError,
  provider
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const instructions: JSX.Element = <p>
      Gusto authentication will continue in a new window. Please make sure to
      allow popups and finish authenticating through Gusto in the new window.
    </p>;
  const [loading, setLoading] = useState(false);
  const externalWindowRef = useRef<Window | null>(null);

  // use any for type until Finch common types can be updated
  const gustoClientId = (client as any)?.gustoConfig?.clientId ?? '';
  const gustoRedirectUri = (client as any)?.gustoConfig?.redirectUri ?? '';
  const handleSubmit = async () => {
    setLoading(true);
    const didMockOAuth = await onMockOAuth();
    if (didMockOAuth) {
      setLoading(false);
      return;
    }
    const url = sandbox ? new URL(`https://api.gusto-demo.com/oauth/authorize`) : new URL(`https://api.gusto.com/oauth/authorize`);
    url.searchParams.set('client_id', gustoClientId);
    url.searchParams.set('redirect_uri', gustoRedirectUri);
    url.searchParams.set('response_type', 'code');
    externalWindowRef.current = window.open(url.toString(), 'popup', 'popup=true');
    const closeWindow = () => {
      setLoading(false);
      externalWindowRef?.current?.close();
    };
    if (!externalWindowRef.current || externalWindowRef.current.closed) return;
    const checkWindowInterval = setInterval(async () => {
      if (externalWindowRef?.current?.closed) {
        setLoading(false);
        clearInterval(checkWindowInterval);
      } else {
        try {
          const urlParams = new URLSearchParams(externalWindowRef?.current?.location.search);
          const code = urlParams.get('code');
          const error = urlParams.get('error');
          if (code) {
            closeWindow();
            await onSubmit({
              clientCode: code,
              providerRedirectUri: gustoRedirectUri
            });
          }
          if (error) {
            setError({
              message: error,
              status: null
            });
            closeWindow();
          }
        } catch (e) {
          // ignore dom exception errors
          if (e instanceof DOMException) {
            return;
          }
          setError({
            message: JSON.stringify(e),
            status: null
          });
          closeWindow();
        }
      }
    }, 500);
  };
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} data-sentry-element="SignInBase" data-sentry-component="SignInGusto" data-sentry-source-file="SignInGusto.tsx">
      <LoaderButton isLoading={loading} disabled={loading} onClick={handleSubmit} style={{
      marginTop: 'auto'
    }} text="Connect" data-sentry-element="LoaderButton" data-sentry-source-file="SignInGusto.tsx" />
    </SignInBase>;
};
export default withAuthorizeContext(SignInGusto);