import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import { SubdomainInput } from 'components/Input/SubdomainInput';
import { getProviderFormLabel } from '../utils';
export function SignInBambooHrCredential({
  isLoading,
  onSubmit,
  handleClick,
  provider
}: RegisterImplementationProps) {
  return <SignInBase formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['subdomain', 'username', 'password']} render={({
    subdomain,
    username,
    password
  }) => {
    return <>
            <MainContainer useNewDesign>
              <SubdomainInput id="subdomain" domain="bamboohr.com" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} label="Subdomain" autoFocus />
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Email" id="username" />
              <PasswordInput value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Authenticate through BambooHR instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={isLoading} disabled={isLoading || !username.value || !password.value} type="submit" text="Connect" />
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInBambooHrCredential" data-sentry-source-file="SignInBambooHrCredential.tsx" />;
}