import appliedBusinessSolutionsLogo from 'assets/img/appliedBusinessSolutionsLogo.jpeg';
import appliedBusinessSolutionsLogoLarge from 'assets/img/appliedBusinessSolutionsLogoLarge.png';
import adpLogo from 'assets/img/adpLogo.svg';
import adpLogoLarge from 'assets/img/adpLogoLarge.svg';
import gustoLogo from 'assets/img/gustoLogo.svg';
import gustoLogoLarge from 'assets/img/gustoLogoLarge.svg';
import justworksLogoLarge from 'assets/img/justworksLogoLarge.png';
import justworksLogo from 'assets/img/justworksLogo.svg';
import quickbooksLogo from 'assets/img/quickbooksLogo.svg';
import quickbooksLogoLarge from 'assets/img/quickbooksLogoLarge.svg';
import ripplingLogo from 'assets/img/ripplingLogo.png';
import ripplingLogoLarge from 'assets/img/ripplingLogoLarge.svg';
import trinetLogo from 'assets/img/trinetLogo.svg';
import trinetLogoLarge from 'assets/img/trinetLogoLarge.svg';
import trinetHRLogo from 'assets/img/trinetHRLogo.webp';
import trinetHRLogoLarge from 'assets/img/trinetHRLogoLarge.svg';
import bambooLogo from 'assets/img/bambooLogo.png';
import bambooLogoLarge from 'assets/img/bambooLogoLarge.png';
import squareLogo from 'assets/img/squareLogo.svg';
import squareLogoLarge from 'assets/img/squareLogoLarge.png';
import paylocityLogo from 'assets/img/paylocityLogo.svg';
import paylocityLogoLarge from 'assets/img/paylocityLogoLarge.png';
import insperityLogoLarge from 'assets/img/insperityLogoLarge.png';
import insperityLogo from 'assets/img/insperityLogo.svg';
import isolvedLogo from 'assets/img/isolvedLogo.png';
import isolvedLogoLarge from 'assets/img/isolvedLogoLarge.png';
import paychexFlexLogo from 'assets/img/paychexFlexLogo.png';
import paychexFlexLogoLarge from 'assets/img/paychexFlexLogoLarge.svg';
import paycorLogo from 'assets/img/paycorLogo.png';
import paycorLogoLarge from 'assets/img/paycorLogoLarge.svg';
import primepayLogo from 'assets/img/primepayLogo.png';
import primepayLogoLarge from 'assets/img/primepayLogoLarge.png';
import gocoLogo from 'assets/img/gocoLogo.png';
import gocoLogoLarge from 'assets/img/gocoLogoLarge.png';
import xeroLogo from 'assets/img/xeroLogo.png';
import xeroLogoLarge from 'assets/img/xeroLogoLarge.png';
import bobLogo from 'assets/img/bobLogo.png';
import bobLogoLarge from 'assets/img/bobLogoLarge.png';
import sagePayrollLogo from 'assets/img/sagePayrollLogo.png';
import sagePayrollLogoLarge from 'assets/img/sageLogoLarge.png';
import wurkLogo from 'assets/img/wurkLogo.png';
import wurkLogoLarge from 'assets/img/wurkLogoLarge.png';
import viewpointHRManagementSpectrumLogo from 'assets/img/viewpointHRManagementSpectrumLogo.png';
import viewpointHRManagementVistaLogo from 'assets/img/viewpointHRManagementVistaLogo.png';
import viewpointLogoLarge from 'assets/img/viewpointLogoLarge.png';
import waveLogo from 'assets/img/waveLogo.png';
import waveLogoLarge from 'assets/img/waveLogoLarge.svg'; // check size
import namelyLogo from 'assets/img/namelyLogo.svg';
import namelyLogoLarge from 'assets/img/namelyLogoLarge.svg';
import balancePointLogo from 'assets/img/balancePointLogo.png';
import balancePointLogoLarge from 'assets/img/balancePointLogoLarge.png';
import aholaLogo from 'assets/img/aholaLogo.png';
import aholaLogoLarge from 'assets/img/aholaLogoLarge.png';
import savantHCMEvolutionLogo from 'assets/img/savantHCMEvolutionLogo.png';
import savantHCMEvolutionLogoLarge from 'assets/img/savantHCMEvolutionLogoLarge.png';
import collageLogo from 'assets/img/collageLogo.png';
import collageLogoLarge from 'assets/img/collageLogoLarge.png';
import thePayrollCompanyLogo from 'assets/img/thePayrollCompanyLogo.png';
import thePayrollCompanyLogoLarge from 'assets/img/thePayrollCompanyLogoLarge.png';
import highflyerHRLogo from 'assets/img/highflyerHRLogo.png';
import highflyerHRLogoLarge from 'assets/img/highflyerHRLogoLarge.png';
import dominionPayrollLogo from 'assets/img/dominionPayrollLogo.png';
import dominionPayrollLogoLarge from 'assets/img/dominionPayrollLogoLarge.png';
import pcsHCMLogo from 'assets/img/pcsHCMLogo.png';
import pcsHCMLogoLarge from 'assets/img/pcsHCMLogoLarge.png';
import payrollNetworkLogo from 'assets/img/payrollNetworkLogo.png';
import payrollNetworkLogoLarge from 'assets/img/payrollNetworkLogoLarge.png';
import bigFishEmployerServicesLogo from 'assets/img/bigFishEmployerServicesLogo.png';
import bigFishEmployerServicesLogoLarge from 'assets/img/bigFishEmployerServicesLogoLarge.png';
import asureSoftwareLogo from 'assets/img/asureSoftwareLogo.png';
import asureSoftwareLogoLarge from 'assets/img/asureSoftwareLogoLarge.png';
import toastPayrollLogo from 'assets/img/toastPayrollLogo.png';
import toastPayrollLogoLarge from 'assets/img/toastPayrollLogoLarge.png';
import patriotLogo from 'assets/img/patriotLogo.png';
import patriotLogoLarge from 'assets/img/patriotLogoLarge.png';
import wagepointLogo from 'assets/img/wagepointLogo.png';
import wagepointLogoLarge from 'assets/img/wagepointLogoLarge.svg'; // check size
import apsLogo from 'assets/img/apsLogo.png';
import apsLogoLarge from 'assets/img/apsLogoLarge.png';
import onpayLogo from 'assets/img/onpayLogo.png';
import onpayLogoLarge from 'assets/img/onpayLogoLarge.png';
import adpTotalSourceLogo from 'assets/img/adpTotalSourceLogo.png';
import adpTotalSourceLogoLarge from 'assets/img/adpTotalSourceLogoLarge.png';
import hubstaffLogo from 'assets/img/hubstaffLogo.png';
import hubstaffLogoLarge from 'assets/img/hubstaffLogoLarge.png';
import mpLogo from 'assets/img/mpLogo.png';
import mpLogoLarge from 'assets/img/mpLogoLarge.svg';
import newtekLogo from 'assets/img/newtekLogo.png';
import newtekLogoLarge from 'assets/img/newtekLogoLarge.png';
import payUSALogo from 'assets/img/payUSALogo.png';
import payUSALogoLarge from 'assets/img/payUSALogoLarge.png';
import syncHRLogo from 'assets/img/syncHRLogo.png';
import syncHRLogoLarge from 'assets/img/syncHRLogoLarge.png';
import affiliatedHRPayrollServicesLogo from 'assets/img/affiliatedHRPayrollServicesLogo.png';
import affiliatedHRPayrollServicesLogoLarge from 'assets/img/affiliatedHRPayrollServicesLogoLarge.png';
import bizchecksPayrollLogo from 'assets/img/bizchecksPayrollLogo.png';
import bizchecksPayrollLogoLarge from 'assets/img/bizchecksPayrollLogoLarge.png';
import crescentPayrollSolutionsLogo from 'assets/img/crescentPayrollSolutionsLogo.png';
import crescentPayrollSolutionsLogoLarge from 'assets/img/crescentPayrollSolutionsLogoLarge.png';
import employdriveLogo from 'assets/img/employdriveLogo.png';
import employdriveLogoLarge from 'assets/img/employdriveLogoLarge.png';
import whirksLogo from 'assets/img/whirksLogo.png';
import whirksLogoLarge from 'assets/img/whirksLogoLarge.png';
import coastalPayrollLogo from 'assets/img/coastalPayrollLogo.png';
import coastalPayrollLogoLarge from 'assets/img/coastalPayrollLogoLarge.png';
import paydayWorkforceSolutionsLogo from 'assets/img/paydayWorkforceSolutionsLogo.png';
import paydayWorkforceSolutionsLogoLarge from 'assets/img/paydayWorkforceSolutionsLogoLarge.png';
import commonwealthPayrollHRLogo from 'assets/img/commonwealthPayrollLogo.png';
import commonwealthPayrollHRLogoLarge from 'assets/img/commonwealthPayrollLogoLarge.png';
import accupayLogo from 'assets/img/accupayLogo.png';
import accupayLogoLarge from 'assets/img/accupayLogoLarge.png';
import assetHRLogo from 'assets/img/assetHRLogo.png';
import assetHRLogoLarge from 'assets/img/assetHRLogoLarge.png';
import gtmPayrollServicesLogo from 'assets/img/gtmPayrollServicesLogo.png';
import gtmPayrollServicesLogoLarge from 'assets/img/gtmPayrollServicesLogoLarge.png';
import platinumGroupLogo from 'assets/img/platinumGroupLogo.png';
import platinumGroupLogoLarge from 'assets/img/platinumGroupLogoLarge.png';
import benecareLogo from 'assets/img/benecareLogo.png';
import benecareLogoLarge from 'assets/img/benecareLogoLarge.png';
import payrollPlusHCMLogo from 'assets/img/payrollPlusHCMLogo.png';
import payrollPlusHCMLogoLarge from 'assets/img/payrollPlusHCMLogoLarge.png';
import saplingLogo from 'assets/img/saplingLogo.png';
import saplingLogoLarge from 'assets/img/saplingLogoLarge.png';
import ceridianDayforceLogo from 'assets/img/ceridianDayforceLogo.png';
import ceridianDayforceLogoLarge from 'assets/img/ceridianDayforceLogoLarge.png';
import successfactorsLogo from 'assets/img/successfactorsLogo.png';
import successfactorsLogoLarge from 'assets/img/successfactorsLogoLarge.png';
import workdayLogo from 'assets/img/workdayLogo.svg';
import workdayLogoLarge from 'assets/img/workdayLogoLarge.svg';
import webHRLogo from 'assets/img/webHRLogo.png';
import webHRLogoLarge from 'assets/img/webHRLogoLarge.png';
import zohoLogo from 'assets/img/zohoLogo.png';
import zohoLogoLarge from 'assets/img/zohoLogoLarge.png';
import finchLogo from 'assets/img/finchLogo.png';
import finchLogoLarge from 'assets/img/finchLogoLarge.svg';
import advantagePayrollServicesLogo from 'assets/img/advantagePayrollServicesLogo.png';
import advantagePayrollServicesLogoLarge from 'assets/img/advantagePayrollServicesLogoLarge.png';
import abacusPayrollLogo from 'assets/img/abacusPayrollLogo.png';
import abacusPayrollLogoLarge from 'assets/img/abacusPayrollLogoLarge.png';
import absoluteLogo from 'assets/img/absoluteLogo.png';
import absoluteLogoLarge from 'assets/img/absoluteLogoLarge.png';
import aceLogo from 'assets/img/aceLogo.png';
import aceLogoLarge from 'assets/img/aceLogoLarge.png';
import baronLogo from 'assets/img/baronLogo.png';
import baronLogoLarge from 'assets/img/baronLogoLarge.png';
import cpmLogo from 'assets/img/cpmLogo.png';
import cpmLogoLarge from 'assets/img/cpmLogoLarge.png';
import paycomLogo from 'assets/img/paycomLogo.svg';
import paycomLogoLarge from 'assets/img/paycomLogoLarge.png';
import sequoiaLogo from 'assets/img/sequoiaLogo.png';
import sequoiaLogoLarge from 'assets/img/sequoiaLogoLarge.png';
import aapLogo from 'assets/img/aapLogo.png';
import aapLogoLarge from 'assets/img/aapLogoLarge.png';
import strategicPayrollSolutionsLogo from 'assets/img/strategicPayrollSolutionsLogo.png';
import strategicPayrollSolutionsLogoLarge from 'assets/img/strategicPayrollSolutionsLogoLarge.png';
import paragonpayrollLogo from 'assets/img/paragonpayrollLogo.png';
import paragonpayrollLogoLarge from 'assets/img/paragonpayrollLogoLarge.png';
import payproHCSLogo from 'assets/img/payproHCSLogo.png';
import payproHCSLogoLarge from 'assets/img/payproHCSLogoLarge.png';
import ceridianPowerpayLogo from 'assets/img/ceridianPowerpayLogo.png';
import ceridianPowerpayLogoLarge from 'assets/img/ceridianPowerpayLogoLarge.png';
import oraclePeopleSoftLogo from 'assets/img/oraclePeopleSoftLogo.png';
import oraclePeopleSoftLogoLarge from 'assets/img/oraclePeopleSoftLogoLarge.png';
import coAdvantageLogo from 'assets/img/coAdvantageLogo.png';
import coAdvantageLogoLarge from 'assets/img/coAdvantageLogoLarge.png';
import remoteLogo from 'assets/img/remoteLogo.png';
import remoteLogoLarge from 'assets/img/remoteLogoLarge.png';
import keystoneLogo from 'assets/img/keystoneLogo.png';
import keystoneLogoLarge from 'assets/img/keystoneLogoLarge.png';
import prismHRLogo from 'assets/img/prismHRLogo.png';
import prismHRLogoLarge from 'assets/img/prismHRLogoLarge.png';
import vensureHRLogo from 'assets/img/vensureHRLogo.png';
import vensureHRLogoLarge from 'assets/img/vensureHRLogoLarge.png';
import proxusHRLogo from 'assets/img/proxusHRLogo.png';
import proxusHRLogoLarge from 'assets/img/proxusHRLogoLarge.png';
import suitePeopleLogo from 'assets/img/suitePeopleLogo.png';
import suitePeopleLogoLarge from 'assets/img/suitePeopleLogoLarge.png';
import charlieHRLogo from 'assets/img/charlieHRLogo.png';
import charlieHRLogoLarge from 'assets/img/charlieHRLogoLarge.png';
import naturalHRLogo from 'assets/img/naturalHRLogo.png';
import naturalHRLogoLarge from 'assets/img/naturalHRLogoLarge.png';
import deelLogo from 'assets/img/deelLogo.png';
import deelLogoLarge from 'assets/img/deelLogoLarge.png';
import ukgreadyLogo from 'assets/img/ukgreadyLogo.png';
import ukgLogoLarge from 'assets/img/ukgLogoLarge.png';
import ukgreadyLogoLarge from 'assets/img/ukgreadyLogoLarge.png';
import proliantLogo from 'assets/img/proliantLogo.png';
import proliantLogoLarge from 'assets/img/proliantLogoLarge.png';
import humiLogo from 'assets/img/humiLogo.png';
import humiLogoLarge from 'assets/img/humiLogoLarge.png';
import bbcsLogo from 'assets/img/bbcsLogo.png';
import bbcsLogoLarge from 'assets/img/bbcsLogoLarge.png';
import nextepLogo from 'assets/img/nextepLogo.png';
import nextepLogoLarge from 'assets/img/nextepLogoLarge.png';
import paynwLogo from 'assets/img/paynwLogo.png';
import paynwLogoLarge from 'assets/img/paynwLogoLarge.png';
import amplifyLogo from 'assets/img/amplifyLogo.png';
import amplifyLogoLarge from 'assets/img/amplifyLogoLarge.png';
import payrollsolutionsLogo from 'assets/img/payrollsolutionsLogo.png';
import payrollsolutionsLogoLarge from 'assets/img/payrollsolutionsLogoLarge.png';
import payrollofficeofamericaLogo from 'assets/img/payrollofficeofamericaLogo.png';
import payrollofficeofamericaLogoLarge from 'assets/img/payrollofficeofamericaLogoLarge.png';
import cognoshrLogo from 'assets/img/cognoshrLogo.png';
import cognoshrLogoLarge from 'assets/img/cognoshrLogoLarge.png';
import empowerhrLogo from 'assets/img/empowerhrLogo.png';
import empowerhrLogoLarge from 'assets/img/empowerhrLogoLarge.png';
import alphastaffLogo from 'assets/img/alphastaffLogo.png';
import alphastaffLogoLarge from 'assets/img/alphastaffLogoLarge.png';
import payfitLogo from 'assets/img/payfitLogo.png';
import payfitLogoLarge from 'assets/img/payfitLogoLarge.png';
import onesourcepayrollLogo from 'assets/img/onesourcepayrollLogo.png';
import onesourcepayrollLogoLarge from 'assets/img/onesourcepayrollLogoLarge.png';
import flockLogo from 'assets/img/flockLogo.png';
import flockLogoLarge from 'assets/img/flockLogoLarge.png';
import rmipeoLogo from 'assets/img/rmipeoLogo.png';
import rmipeoLogoLarge from 'assets/img/rmipeoLogoLarge.png';
import worklioLogo from 'assets/img/worklioLogo.png';
import worklioLogoLarge from 'assets/img/worklioLogoLarge.png';
import doylehcmLogo from 'assets/img/doylehcmLogo.png';
import doylehcmLogoLarge from 'assets/img/doylehcmLogoLarge.png';
import oasisLogo from 'assets/img/oasisLogo.png';
import oasisLogoLarge from 'assets/img/oasisLogoLarge.png';
import excelresourcesLogo from 'assets/img/excelresourcesLogo.png';
import excelresourcesLogoLarge from 'assets/img/excelresourcesLogoLarge.png';
import sheakleyLogo from 'assets/img/sheakleyLogo.png';
import sheakleyLogoLarge from 'assets/img/sheakleyLogoLarge.png';
import propelHrLogo from 'assets/img/propelHrLogo.png';
import propelHrLogoLarge from 'assets/img/propelHrLogoLarge.png';
import adamskeeganLogo from 'assets/img/adamskeeganLogo.png';
import adamskeeganLogoLarge from 'assets/img/adamskeeganLogoLarge.png';
import deluxeLogo from 'assets/img/deluxeLogo.png';
import deluxeLogoLarge from 'assets/img/deluxeLogoLarge.png';
import workzoomLogo from 'assets/img/workzoomLogo.png';
import workzoomLogoLarge from 'assets/img/workzoomLogoLarge.png';
import bbsiLogo from 'assets/img/bbsiLogo.png';
import bbsiLogoLarge from 'assets/img/bbsiLogoLarge.png';
import vfficientLogo from 'assets/img/vfficientLogo.png';
import vfficientLogoLarge from 'assets/img/vfficientLogoLarge.png';
import surepayrollLogo from 'assets/img/surepayrollLogo.png';
import surepayrollLogoLarge from 'assets/img/surepayrollLogoLarge.png';
import prestigepeoLogo from 'assets/img/prestigepeoLogo.jpeg';
import prestigepeoLogoLarge from 'assets/img/prestigepeoLogoLarge.png';
import infinitihrLogo from 'assets/img/infinitihrLogo.jpeg';
import infinitihrLogoLarge from 'assets/img/infinitihrLogoLarge.png';
import heartlandLogo from 'assets/img/heartlandLogo.jpeg';
import heartlandLogoLarge from 'assets/img/heartlandLogoLarge.png';
import gnapartnersLogo from 'assets/img/gnapartnersLogo.jpeg';
import gnapartnersLogoLarge from 'assets/img/gnapartnersLogoLarge.svg';
import emplicityLogo from 'assets/img/emplicityLogo.jpeg';
import emplicityLogoLarge from 'assets/img/emplicityLogoLarge.jpeg';
import engagepeoLogo from 'assets/img/engagepeoLogo.jpeg';
import engagepeoLogoLarge from 'assets/img/engagepeoLogoLarge.png';
import escLogo from 'assets/img/escLogo.jpeg';
import escLogoLarge from 'assets/img/escLogoLarge.png';
import evereeLogo from 'assets/img/evereeLogo.jpeg';
import evereeLogoLarge from 'assets/img/evereeLogoLarge.png';
import greythrLogo from 'assets/img/greythrLogo.jpeg';
import greythrLogoLarge from 'assets/img/greythrLogoLarge.svg';
import irishrLogo from 'assets/img/irishrLogo.jpeg';
import irishrLogoLarge from 'assets/img/irishrLogoLarge.png';
import kenjoLogo from 'assets/img/kenjoLogo.svg';
import kenjoLogoLarge from 'assets/img/kenjoLogoLarge.svg';
import mpayLogo from 'assets/img/mpayLogo.jpeg';
import mpayLogoLarge from 'assets/img/mpayLogoLarge.jpeg';
import simployLogo from 'assets/img/simployLogo.png';
import simployLogoLarge from 'assets/img/simployLogoLarge.png';
import skuadLogo from 'assets/img/skuadLogo.jpeg';
import skuadLogoLarge from 'assets/img/skuadLogoLarge.svg';
import threadhcmLogo from 'assets/img/threadhcmLogo.jpeg';
import threadhcmLogoLarge from 'assets/img/threadhcmLogoLarge.png';
import trakstarLogo from 'assets/img/trakstarLogo.svg';
import trakstarLogoLarge from 'assets/img/trakstarLogoLarge.svg';
import xeniumhrLogo from 'assets/img/xeniumhrLogo.jpeg';
import xeniumhrLogoLarge from 'assets/img/xeniumhrLogoLarge.jpeg';
import fullstackpeoLogo from 'assets/img/fullstackpeoLogo.png';
import fullstackpeoLogoLarge from 'assets/img/fullstackpeoLogoLarge.png';
import hroLogo from 'assets/img/hroLogo.png';
import hroLogoLarge from 'assets/img/hroLogoLarge.jpeg';
import peopleleaseLogo from 'assets/img/peopleleaseLogo.jpeg';
import peopleleaseLogoLarge from 'assets/img/peopleleaseLogoLarge.png';
import velocityglobalLogo from 'assets/img/velocityglobalLogo.jpeg';
import velocityglobalLogoLarge from 'assets/img/velocityglobalLogoLarge.jpeg';
import accountantsworldLogo from 'assets/img/accountantsworldLogo.jpeg';
import accountantsworldLogoLarge from 'assets/img/accountantsworldLogoLarge.jpeg';
import basicLogo from 'assets/img/basicLogo.png';
import basicLogoLarge from 'assets/img/basicLogoLarge.png';
import dmpayrollsolutionsLogo from 'assets/img/dmpayrollsolutionsLogo.png';
import dmpayrollsolutionsLogoLarge from 'assets/img/dmpayrollsolutionsLogoLarge.jpeg';
import hroneLogo from 'assets/img/hroneLogo.jpeg';
import hroneLogoLarge from 'assets/img/hroneLogoLarge.svg';
import peoplehumLogo from 'assets/img/peoplehumLogo.jpeg';
import peoplehumLogoLarge from 'assets/img/peoplehumLogoLarge.svg';
import sourceoneLogo from 'assets/img/sourceoneLogo.jpeg';
import sourceoneLogoLarge from 'assets/img/sourceoneLogoLarge.png';
import evolutionpayrollservicesLogo from 'assets/img/evolutionpayrollservicesLogo.webp';
import evolutionpayrollservicesLogoLarge from 'assets/img/evolutionpayrollservicesLogoLarge.jpeg';
import humaansLogo from 'assets/img/humaansLogo.png';
import humaansLogoLarge from 'assets/img/humaansLogoLarge.svg';
import pantherLogo from 'assets/img/pantherLogo.png';
import pantherLogoLarge from 'assets/img/pantherLogoLarge.svg';
import personioLogo from 'assets/img/personioLogo.png';
import personioLogoLarge from 'assets/img/personioLogoLarge.png';
import peopleforceLogo from 'assets/img/peopleforceLogo.avif';
import peopleforceLogoLarge from 'assets/img/peopleforceLogoLarge.webp';
import inforLogo from 'assets/img/inforLogo.png';
import inforLogoLarge from 'assets/img/inforLogoLarge.png';
import opolisLogo from 'assets/img/opolisLogo.png';
import opolisLogoLarge from 'assets/img/opolisLogoLarge.png';
import employerflexibleLogo from 'assets/img/employerflexibleLogo.png';
import employerflexibleLogoLarge from 'assets/img/employerflexibleLogoLarge.png';
import netchexLogo from 'assets/img/netchexLogo.png';
import netchexLogoLarge from 'assets/img/netchexLogoLarge.png';
import workforcejunctionLogo from 'assets/img/workforcejunctionLogo.png';
import workforcejunctionLogoLarge from 'assets/img/workforcejunctionLogoLarge.png';
import connectpayLogo from 'assets/img/connectpayLogo.png';
import connectpayLogoLarge from 'assets/img/connectpayLogoLarge.png';
import bcnservicesLogo from 'assets/img/bcnservicesLogo.png';
import bcnservicesLogoLarge from 'assets/img/bcnservicesLogoLarge.png';
import eddyhrLogo from 'assets/img/eddyhrLogo.png';
import eddyhrLogoLarge from 'assets/img/eddyhrLogoLarge.png';
import sageHrLogo from 'assets/img/sageHrLogo.png';
import sageHrLogoLarge from 'assets/img/sageHrLogoLarge.svg';
import cezannehrLogo from 'assets/img/cezannehrLogo.png';
import cezannehrLogoLarge from 'assets/img/cezannehrLogoLarge.png';
import oraclefusionLogo from 'assets/img/oraclefusionLogo.png';
import oraclefusionLogoLarge from 'assets/img/oraclefusionLogoLarge.jpeg';
import horizonsLogo from 'assets/img/horizonsLogo.png';
import horizonsLogoLarge from 'assets/img/horizonsLogoLarge.png';
import multiplierLogo from 'assets/img/multiplierLogo.png';
import multiplierLogoLarge from 'assets/img/multiplierLogoLarge.png';
import odooLogo from 'assets/img/odooLogo.png';
import odooLogoLarge from 'assets/img/odooLogoLarge.png';
import prosoftwareLogo from 'assets/img/prosoftwareLogo.png';
import prosoftwareLogoLarge from 'assets/img/prosoftwareLogoLarge.png';
import southeastpersonnelLogo from 'assets/img/southeastpersonnelLogo.png';
import southeastpersonnelLogoLarge from 'assets/img/southeastpersonnelLogoLarge.png';
import decentLogo from 'assets/img/decentLogo.png';
import decentLogoLarge from 'assets/img/decentLogoLarge.png';
import resourcingEdgeLogo from 'assets/img/resourcingEdgeLogo.png';
import resourcingEdgeLogoLarge from 'assets/img/resourcingEdgeLogoLarge.png';
import businessOnlinePayrollLogo from 'assets/img/businessOnlinePayrollLogo.png';
import businessOnlinePayrollLogoLarge from 'assets/img/businessOnlinePayrollLogoLarge.png';
import adpcompLogo from 'assets/img/adpcompLogo.png';
import adpcompLogoLarge from 'assets/img/adpcompLogoLarge.png';
import solutionservicesLogo from 'assets/img/solutionservicesLogo.png';
import solutionservicesLogoLarge from 'assets/img/solutionservicesLogoLarge.png';
import uzioLogo from 'assets/img/uzioLogo.png';
import uzioLogoLarge from 'assets/img/uzioLogoLarge.png';
import clickupLogo from 'assets/img/clickupLogo.png';
import clickupLogoLarge from 'assets/img/clickupLogoLarge.png';
import paytimeLogo from 'assets/img/paytimeLogo.png';
import paytimeLogoLarge from 'assets/img/paytimeLogoLarge.png';
import deltekLogo from 'assets/img/deltekLogo.png';
import deltekLogoLarge from 'assets/img/deltekLogoLarge.png';
import freshteamLogo from 'assets/img/freshteamLogo.png';
import freshteamLogoLarge from 'assets/img/freshteamLogoLarge.png';
import gigwageLogo from 'assets/img/gigwageLogo.png';
import gigwageLogoLarge from 'assets/img/gigwageLogoLarge.png';
import teamworksLogo from 'assets/img/teamworksLogo.png';
import teamworksLogoLarge from 'assets/img/teamworksLogoLarge.png';
import hrcloudLogo from 'assets/img/hrcloudLogo.png';
import hrcloudLogoLarge from 'assets/img/hrcloudLogoLarge.png';
import darwinboxLogo from 'assets/img/darwinboxLogo.png';
import darwinboxLogoLarge from 'assets/img/darwinboxLogoLarge.png';
import extensisHRLogo from 'assets/img/extensisHRLogo.png';
import extensisHRLogoLarge from 'assets/img/extensisHRLogoLarge.png';
import adpTeampayLogo from 'assets/img/adpTeampayLogo.svg';
import adpTeampayLogoLarge from 'assets/img/adpTeampayLogoLarge.svg';
import obsidianhrLogo from 'assets/img/obsidianhrLogo.png';
import obsidianhrLogoLarge from 'assets/img/obsidianhrLogoLarge.png';
import workplacehcmLogo from 'assets/img/workplacehcmLogo.png';
import workplacehcmLogoLarge from 'assets/img/workplacehcmLogoLarge.png';
import viventiumLogo from 'assets/img/viventiumLogo.png';
import viventiumLogoLarge from 'assets/img/viventiumLogoLarge.png';
import cscPaymasterLogo from 'assets/img/cscPaymasterLogo.png';
import cscPaymasterLogoLarge from 'assets/img/cscPaymasterLogoLarge.png';
import helpsideLogo from 'assets/img/helpsideLogo.png';
import helpsideLogoLarge from 'assets/img/helpsideLogoLarge.png';
import apdataLogo from 'assets/img/apdataLogo.png';
import apdataLogoLarge from 'assets/img/apdataLogoLarge.png';
import c2Logo from 'assets/img/c2Logo.png';
import c2LogoLarge from 'assets/img/c2LogoLarge.png';
import alcottHRLogo from 'assets/img/alcottHRLogo.png';
import alcottHRLogoLarge from 'assets/img/alcottHRLogoLarge.png';
import bambeeLogo from 'assets/img/bambeeLogo.png';
import bambeeLogoLarge from 'assets/img/bambeeLogoLarge.png';
import qualitypayrollLogo from 'assets/img/qualitypayrollLogo.png';
import qualitypayrollLogoLarge from 'assets/img/qualitypayrollLogoLarge.png';
import PRGLogo from 'assets/img/PRGLogo.png';
import PRGLogoLarge from 'assets/img/PRGLogoLarge.png';
import ventureworkforceLogo from 'assets/img/ventureworkforceLogo.png';
import ventureworkforceLogoLarge from 'assets/img/ventureworkforceLogoLarge.png';
import nvisionLogo from 'assets/img/nvisionLogo.jpeg';
import nvisionLogoLarge from 'assets/img/nvisionLogoLarge.jpeg';
import creativebusinessLogo from 'assets/img/creativebusinessLogo.png';
import creativebusinessLogoLarge from 'assets/img/creativebusinessLogoLarge.png';
import homebaseLogo from 'assets/img/homebaseLogo.png';
import homebaseLogoLarge from 'assets/img/homebaseLogoLarge.png';
import horizonpayrollLogo from 'assets/img/horizonpayrollLogo.png';
import horizonpayrollLogoLarge from 'assets/img/horizonpayrollLogoLarge.png';
import apexLogo from 'assets/img/apexLogo.png';
import apexLogoLarge from 'assets/img/apexLogoLarge.png';
import payentryLogo from 'assets/img/payentryLogo.png';
import payentryLogoLarge from 'assets/img/payentryLogoLarge.png';
import questcoLogo from 'assets/img/questcoLogo.png';
import questcoLogoLarge from 'assets/img/questcoLogoLarge.png';
import northbaypayrollLogo from 'assets/img/northbaypayrollLogo.png';
import northbaypayrollLogoLarge from 'assets/img/northbaypayrollLogoLarge.png';
import costaffLogo from 'assets/img/costaffLogo.png';
import costaffLogoLarge from 'assets/img/costaffLogoLarge.png';
import corporatepayrollLogo from 'assets/img/corporatepayrollLogo.png';
import corporatepayrollLogoLarge from 'assets/img/corporatepayrollLogoLarge.png';
import tandemHRLogo from 'assets/img/tandemHRLogo.png';
import tandemHRLogoLarge from 'assets/img/tandemHRLogoLarge.png';
import runforpartnersLogo from 'assets/img/runforpartnersLogo.png';
import runforpartnersLogoLarge from 'assets/img/runforpartnersLogoLarge.png';
import onepointhcmLogo from 'assets/img/onepointhcmLogo.png';
import onepointhcmLogoLarge from 'assets/img/onepointhcmLogoLarge.png';
import payrollontimeLogo from 'assets/img/payrollontimeLogo.png';
import payrollontimeLogoLarge from 'assets/img/payrollontimeLogoLarge.png';
import payroll4constructionLogo from 'assets/img/payroll4constructionLogo.png';
import payroll4constructionLogoLarge from 'assets/img/payroll4constructionLogoLarge.png';
import adpvantageLogo from 'assets/img/adpvantageLogo.png';
import adpvantageLogoLarge from 'assets/img/adpvantageLogoLarge.png';
import emcentrixLogo from 'assets/img/emcentrixLogo.png';
import emcentrixLogoLarge from 'assets/img/emcentrixLogoLarge.png';
import freshworksLogo from 'assets/img/freshworksLogo.png';
import freshworksLogoLarge from 'assets/img/freshworksLogoLarge.png';
import businessSolverLogo from 'assets/img/businessSolverLogo.png';
import businessSolverLogoLarge from 'assets/img/businessSolverLogoLarge.png';
import factorialHRLogo from 'assets/img/factorialHRLogo.svg';
import factorialHRLogoLarge from 'assets/img/factorialHRLogoLarge.svg';
import checkwritersLogo from 'assets/img/checkwritersLogo.png';
import checkwritersLogoLarge from 'assets/img/checkwritersLogoLarge.png';
import oysterHrLogo from 'assets/img/oysterHrLogo.webp';
import OysterHrLogoLarge from 'assets/img/oysterHrLogoLarge.jpg';
import payfluenceLogo from 'assets/img/payfluenceLogo.png';
import payfluenceLogoLarge from 'assets/img/payfluenceLogoLarge.png';
import peoplecoreLogo from 'assets/img/peoplecoreLogo.png';
import peoplecoreLogoLarge from 'assets/img/peoplecoreLogoLarge.png';
import sunfishLogo from 'assets/img/sunfishLogo.png';
import sunfishLogoLarge from 'assets/img/sunfishLogoLarge.png';
import kekaLogo from 'assets/img/kekaLogo.png';
import kekaLogoLarge from 'assets/img/kekaLogoLarge.png';
import synchronyhrLogo from 'assets/img/synchronyhrLogo.png';
import synchronyhrLogoLarge from 'assets/img/synchronyhrLogoLarge.png';
import zimyoLogo from 'assets/img/zimyoLogo.png';
import zimyoLogoLarge from 'assets/img/zimyoLogoLarge.png';
import wolkeLogo from 'assets/img/wolkeLogo.png';
import wolkeLogoLarge from 'assets/img/wolkeLogoLarge.png';
import quickhrLogo from 'assets/img/quickhrLogo.png';
import quickhrLogoLarge from 'assets/img/quickhrLogoLarge.png';
import talentaLogo from 'assets/img/talentaLogo.png';
import talentaLogoLarge from 'assets/img/talentaLogoLarge.png';
import adpnextgenLogo from 'assets/img/adpnextgenLogo.png';
import adpnextgenLogoLarge from 'assets/img/adpnextgenLogoLarge.png';
import aspenhrLogo from 'assets/img/aspenhrLogo.png';
import aspenhrLogoLarge from 'assets/img/aspenhrLogoLarge.png';
import hybridLogo from 'assets/img/hybridLogo.png';
import hybridLogoLarge from 'assets/img/hybridLogoLarge.png';
import premiernowLogo from 'assets/img/premiernowLogo.png';
import premiernowLogoLarge from 'assets/img/premiernowLogoLarge.png';
import bayzatLogo from 'assets/img/bayzatLogo.png';
import bayzatLogoLarge from 'assets/img/bayzatLogoLarge.png';
import strongpayLogo from 'assets/img/strongpayLogo.png';
import strongpayLogoLarge from 'assets/img/strongpayLogoLarge.png';
import adpEnterpriseLogo from 'assets/img/adpEnterpriseLogo.png';
import adpEnterpriseLogoLarge from 'assets/img/adpEnterpriseLogoLarge.png';
import allianceLogo from 'assets/img/allianceLogo.png';
import allianceLogoLarge from 'assets/img/allianceLogoLarge.png';
import remotepassLogo from 'assets/img/remotepassLogo.png';
import remotepassLogoLarge from 'assets/img/remotepassLogoLarge.png';
import elitepayrollLogo from 'assets/img/elitepayrollLogo.png';
import elitepayrollLogoLarge from 'assets/img/elitepayrollLogoLarge.png';
import armhrLogo from 'assets/img/armhrLogo.png';
import armhrLogoLarge from 'assets/img/armhrLogoLarge.png';
import alightLogo from 'assets/img/alightLogo.png';
import alightLogoLarge from 'assets/img/alightLogoLarge.png';
import infinipayLogo from 'assets/img/infinipayLogo.png';
import infinipayLogoLarge from 'assets/img/infinipayLogoLarge.png';
import warpLogo from 'assets/img/warpLogo.png';
import warpLogoLarge from 'assets/img/warpLogoLarge.png';
import miterLogo from 'assets/img/miterLogo.png';
import miterLogoLarge from 'assets/img/miterLogoLarge.png';
import sesameLogo from 'assets/img/sesameLogo.png';
import sesameLogoLarge from 'assets/img/sesameLogoLarge.png';
import OmniHRLogo from 'assets/img/OmniHRLogo.png';
import OmniHRLogoLarge from 'assets/img/OmniHRLogoLarge.png';
import counterpointHcmLogo from 'assets/img/counterpointHcmLogo.png';
import counterpointHcmLogoLarge from 'assets/img/counterpointHcmLogoLarge.png';
import cmicLogo from 'assets/img/cmicLogo.png';
import cmicLogoLarge from 'assets/img/cmicLogoLarge.png';
import pentaLogo from 'assets/img/pentaLogo.png';
import pentaLogoLarge from 'assets/img/pentaLogoLarge.png';
import criterionLogo from 'assets/img/criterionLogo.png';
import criterionLogoLarge from 'assets/img/criterionLogoLarge.png';
import employmentheroLogo from 'assets/img/employmentheroLogo.png';
import employmentheroLogoLarge from 'assets/img/employmentheroLogoLarge.png';
import restaurant365Logo from 'assets/img/restaurant365Logo.png';
import restaurant365LogoLarge from 'assets/img/restaurant365LogoLarge.png';
import greenleafLogo from 'assets/img/greenleafLogo.png';
import greenleafLogoLarge from 'assets/img/greenleafLogoLarge.png';
import ecmsLogo from 'assets/img/ecmsLogo.png';
import ecmsLogoLarge from 'assets/img/ecmsLogoLarge.png';
import trionLogo from 'assets/img/trionLogo.png';
import trionLogoLarge from 'assets/img/trionLogoLarge.png';
import sage100Logo from 'assets/img/sage100Logo.png';
import sage100LogoLarge from 'assets/img/sage100LogoLarge.png';

const payrollProviderToLocalLogosMap: Record<
  string,
  { logo: string; largeLogo: string }
> = {
  aap_isolved: {
    logo: aapLogo,
    largeLogo: aapLogoLarge,
  },
  abacus_isolved: {
    logo: abacusPayrollLogo,
    largeLogo: abacusPayrollLogoLarge,
  },
  absolute_isolved: {
    logo: absoluteLogo,
    largeLogo: absoluteLogoLarge,
  },
  accupay_isolved: {
    logo: accupayLogo,
    largeLogo: accupayLogoLarge,
  },
  ace_isolved: {
    logo: aceLogo,
    largeLogo: aceLogoLarge,
  },
  accountantsworld: {
    logo: accountantsworldLogo,
    largeLogo: accountantsworldLogoLarge,
  },
  adams_keegan: {
    logo: adamskeeganLogo,
    largeLogo: adamskeeganLogoLarge,
  },
  adp_totalsource: {
    logo: adpTotalSourceLogo,
    largeLogo: adpTotalSourceLogoLarge,
  },
  adp_comprehensive: {
    logo: adpcompLogo,
    largeLogo: adpcompLogoLarge,
  },
  adp_enterprise: {
    logo: adpEnterpriseLogo,
    largeLogo: adpEnterpriseLogoLarge,
  },
  adp_vantage: {
    logo: adpvantageLogo,
    largeLogo: adpvantageLogoLarge,
  },
  adp_workforce_now: {
    logo: adpLogo,
    largeLogo: adpLogoLarge,
  },
  adpnextgen: {
    logo: adpnextgenLogo,
    largeLogo: adpnextgenLogoLarge,
  },
  advantage_payroll_services: {
    logo: advantagePayrollServicesLogo,
    largeLogo: advantagePayrollServicesLogoLarge,
  },
  affiliated_hr_payroll_services_isolved: {
    logo: affiliatedHRPayrollServicesLogo,
    largeLogo: affiliatedHRPayrollServicesLogoLarge,
  },
  affiliated_hr_payroll_services_evolution: {
    logo: affiliatedHRPayrollServicesLogo,
    largeLogo: affiliatedHRPayrollServicesLogoLarge,
  },
  ahola: {
    logo: aholaLogo,
    largeLogo: aholaLogoLarge,
  },
  alcott_hr: {
    logo: alcottHRLogo,
    largeLogo: alcottHRLogoLarge,
  },
  alight: {
    logo: alightLogo,
    largeLogo: alightLogoLarge,
  },
  alliance: {
    logo: allianceLogo,
    largeLogo: allianceLogoLarge,
  },
  alphastaff: {
    logo: alphastaffLogo,
    largeLogo: alphastaffLogoLarge,
  },
  amplify_hr: {
    logo: amplifyLogo,
    largeLogo: amplifyLogoLarge,
  },
  apdata: {
    logo: apdataLogo,
    largeLogo: apdataLogoLarge,
  },
  apex: {
    logo: apexLogo,
    largeLogo: apexLogoLarge,
  },
  applied_business_solutions: {
    logo: appliedBusinessSolutionsLogo,
    largeLogo: appliedBusinessSolutionsLogoLarge,
  },
  aps_payroll: {
    logo: apsLogo,
    largeLogo: apsLogoLarge,
  },
  armhr: {
    logo: armhrLogo,
    largeLogo: armhrLogoLarge,
  },
  aspenhr: {
    logo: aspenhrLogo,
    largeLogo: aspenhrLogoLarge,
  },
  asset_hr: {
    logo: assetHRLogo,
    largeLogo: assetHRLogoLarge,
  },
  asure_software: {
    logo: asureSoftwareLogo,
    largeLogo: asureSoftwareLogoLarge,
  },
  balance_point: {
    logo: balancePointLogo,
    largeLogo: balancePointLogoLarge,
  },
  bambee: {
    logo: bambeeLogo,
    largeLogo: bambeeLogoLarge,
  },
  bamboo_hr: {
    logo: bambooLogo,
    largeLogo: bambooLogoLarge,
  },
  baron_isolved: {
    logo: baronLogo,
    largeLogo: baronLogoLarge,
  },
  basic: {
    logo: basicLogo,
    largeLogo: basicLogoLarge,
  },
  bayzat: {
    logo: bayzatLogo,
    largeLogo: bayzatLogoLarge,
  },
  bbcs: {
    logo: bbcsLogo,
    largeLogo: bbcsLogoLarge,
  },
  bcn_services: {
    logo: bcnservicesLogo,
    largeLogo: bcnservicesLogoLarge,
  },
  bbsi: {
    logo: bbsiLogo,
    largeLogo: bbsiLogoLarge,
  },
  big_fish_employer_services: {
    logo: bigFishEmployerServicesLogo,
    largeLogo: bigFishEmployerServicesLogoLarge,
  },
  benecare: {
    logo: benecareLogo,
    largeLogo: benecareLogoLarge,
  },
  bizchecks_payroll: {
    logo: bizchecksPayrollLogo,
    largeLogo: bizchecksPayrollLogoLarge,
  },
  bob: {
    logo: bobLogo,
    largeLogo: bobLogoLarge,
  },
  business_online_payroll: {
    logo: businessOnlinePayrollLogo,
    largeLogo: businessOnlinePayrollLogoLarge,
  },
  businesssolver: {
    logo: businessSolverLogo,
    largeLogo: businessSolverLogoLarge,
  },
  c2: {
    logo: c2Logo,
    largeLogo: c2LogoLarge,
  },
  ceridian_dayforce: {
    logo: ceridianDayforceLogo,
    largeLogo: ceridianDayforceLogoLarge,
  },
  ceridian_powerpay: {
    logo: ceridianPowerpayLogo,
    largeLogo: ceridianPowerpayLogoLarge,
  },
  cezannehr: {
    logo: cezannehrLogo,
    largeLogo: cezannehrLogoLarge,
  },
  charlie_hr: {
    logo: charlieHRLogo,
    largeLogo: charlieHRLogoLarge,
  },
  checkwriters: {
    logo: checkwritersLogo,
    largeLogo: checkwritersLogoLarge,
  },
  clickup: {
    logo: clickupLogo,
    largeLogo: clickupLogoLarge,
  },
  cmic: {
    logo: cmicLogo,
    largeLogo: cmicLogoLarge,
  },
  coadvantage: {
    logo: coAdvantageLogo,
    largeLogo: coAdvantageLogoLarge,
  },
  cognos_hr: {
    logo: cognoshrLogo,
    largeLogo: cognoshrLogoLarge,
  },
  collage: {
    logo: collageLogo,
    largeLogo: collageLogoLarge,
  },
  coastal_payroll: {
    logo: coastalPayrollLogo,
    largeLogo: coastalPayrollLogoLarge,
  },
  commonwealth_payroll_hr: {
    logo: commonwealthPayrollHRLogo,
    largeLogo: commonwealthPayrollHRLogoLarge,
  },
  connectpay: {
    logo: connectpayLogo,
    largeLogo: connectpayLogoLarge,
  },
  counterpoint_hcm: {
    logo: counterpointHcmLogo,
    largeLogo: counterpointHcmLogoLarge,
  },  
  corporatepayroll: {
    logo: corporatepayrollLogo,
    largeLogo: corporatepayrollLogoLarge,
  },
  costaff: {
    logo: costaffLogo,
    largeLogo: costaffLogoLarge,
  },
  cpm_isolved: {
    logo: cpmLogo,
    largeLogo: cpmLogoLarge,
  },
  creative_business_resources: {
    logo: creativebusinessLogo,
    largeLogo: creativebusinessLogoLarge,
  },
  crescent_payroll_solutions: {
    logo: crescentPayrollSolutionsLogo,
    largeLogo: crescentPayrollSolutionsLogoLarge,
  },
  criterion: {
    logo: criterionLogo,
    largeLogo: criterionLogoLarge,
  },
  csc_paymaster: {
    logo: cscPaymasterLogo,
    largeLogo: cscPaymasterLogoLarge,
  },
  darwinbox: {
    logo: darwinboxLogo,
    largeLogo: darwinboxLogoLarge,
  },
  decent: {
    logo: decentLogo,
    largeLogo: decentLogoLarge,
  },
  deel: {
    logo: deelLogo,
    largeLogo: deelLogoLarge,
  },
  deltek: {
    logo: deltekLogo,
    largeLogo: deltekLogoLarge,
  },
  deluxe: {
    logo: deluxeLogo,
    largeLogo: deluxeLogoLarge,
  },
  dm_payroll_solutions: {
    logo: dmpayrollsolutionsLogo,
    largeLogo: dmpayrollsolutionsLogoLarge,
  },
  dominion_payroll: {
    logo: dominionPayrollLogo,
    largeLogo: dominionPayrollLogoLarge,
  },
  doyle_hcm: {
    logo: doylehcmLogo,
    largeLogo: doylehcmLogoLarge,
  },
  ecms: {
    logo: ecmsLogo,
    largeLogo: ecmsLogoLarge,
  },
  eddyhr: {
    logo: eddyhrLogo,
    largeLogo: eddyhrLogoLarge,
  },
  elite_payroll: {
    logo: elitepayrollLogo,
    largeLogo: elitepayrollLogoLarge,
  },
  emcentrix: {
    logo: emcentrixLogo,
    largeLogo: emcentrixLogoLarge,
  },
  emplicity: {
    logo: emplicityLogo,
    largeLogo: emplicityLogoLarge,
  },
  employdrive: {
    logo: employdriveLogo,
    largeLogo: employdriveLogoLarge,
  },
  employer_flexible: {
    logo: employerflexibleLogo,
    largeLogo: employerflexibleLogoLarge,
  },
  employmenthero: {
    logo: employmentheroLogo,
    largeLogo: employmentheroLogoLarge,
  },
  empower_hr: {
    logo: empowerhrLogo,
    largeLogo: empowerhrLogoLarge,
  },
  engage_peo: {
    logo: engagepeoLogo,
    largeLogo: engagepeoLogoLarge,
  },
  esc: {
    logo: escLogo,
    largeLogo: escLogoLarge,
  },
  everee: {
    logo: evereeLogo,
    largeLogo: evereeLogoLarge,
  },
  evolution_payroll_services: {
    logo: evolutionpayrollservicesLogo,
    largeLogo: evolutionpayrollservicesLogoLarge,
  },
  excel_resources: {
    logo: excelresourcesLogo,
    largeLogo: excelresourcesLogoLarge,
  },
  extensis_hr: {
    logo: extensisHRLogo,
    largeLogo: extensisHRLogoLarge,
  },
  factorial_hr: {
    logo: factorialHRLogo,
    largeLogo: factorialHRLogoLarge,
  },
  finch: {
    logo: finchLogo,
    largeLogo: finchLogoLarge,
  },
  flock: {
    logo: flockLogo,
    largeLogo: flockLogoLarge,
  },
  freshteam: {
    logo: freshteamLogo,
    largeLogo: freshteamLogoLarge,
  },
  freshworks: {
    logo: freshworksLogo,
    largeLogo: freshworksLogoLarge,
  },
  fullstack_peo: {
    logo: fullstackpeoLogo,
    largeLogo: fullstackpeoLogoLarge,
  },
  gna_partners: {
    logo: gnapartnersLogo,
    largeLogo: gnapartnersLogoLarge,
  },
  gigwage: {
    logo: gigwageLogo,
    largeLogo: gigwageLogoLarge,
  },
  goco: {
    logo: gocoLogo,
    largeLogo: gocoLogoLarge,
  },
  greenleaf: {
    logo: greenleafLogo,
    largeLogo: greenleafLogoLarge,
  },
  greyt_hr: {
    logo: greythrLogo,
    largeLogo: greythrLogoLarge,
  },
  gusto: {
    logo: gustoLogo,
    largeLogo: gustoLogoLarge,
  },
  gtm_payroll_services_isolved: {
    logo: gtmPayrollServicesLogo,
    largeLogo: gtmPayrollServicesLogoLarge,
  },
  gtm_payroll_services_evolution: {
    logo: gtmPayrollServicesLogo,
    largeLogo: gtmPayrollServicesLogoLarge,
  },
  heartland: {
    logo: heartlandLogo,
    largeLogo: heartlandLogoLarge,
  },
  helpside: {
    logo: helpsideLogo,
    largeLogo: helpsideLogoLarge,
  },
  highflyer_hr: {
    logo: highflyerHRLogo,
    largeLogo: highflyerHRLogoLarge,
  },
  homebase: {
    logo: homebaseLogo,
    largeLogo: homebaseLogoLarge,
  },
  horizonpayroll: {
    logo: horizonpayrollLogo,
    largeLogo: horizonpayrollLogoLarge,
  },
  horizons: {
    logo: horizonsLogo,
    largeLogo: horizonsLogoLarge,
  },
  hrcloud: {
    logo: hrcloudLogo,
    largeLogo: hrcloudLogoLarge,
  },
  hro: {
    logo: hroLogo,
    largeLogo: hroLogoLarge,
  },
  hrone: {
    logo: hroneLogo,
    largeLogo: hroneLogoLarge,
  },
  hubstaff: {
    logo: hubstaffLogo,
    largeLogo: hubstaffLogoLarge,
  },
  humaans: {
    logo: humaansLogo,
    largeLogo: humaansLogoLarge,
  },
  humi: {
    logo: humiLogo,
    largeLogo: humiLogoLarge,
  },
  hybrid: {
    logo: hybridLogo,
    largeLogo: hybridLogoLarge,
  },
  infinipay: {
    logo: infinipayLogo,
    largeLogo: infinipayLogoLarge,
  },
  infiniti_hr: {
    logo: infinitihrLogo,
    largeLogo: infinitihrLogoLarge,
  },
  infor: {
    logo: inforLogo,
    largeLogo: inforLogoLarge,
  },
  insperity: {
    logo: insperityLogo,
    largeLogo: insperityLogoLarge,
  },
  iris_hr: {
    logo: irishrLogo,
    largeLogo: irishrLogoLarge,
  },
  isolved: {
    logo: isolvedLogo,
    largeLogo: isolvedLogoLarge,
  },
  justworks: {
    logo: justworksLogo,
    largeLogo: justworksLogoLarge,
  },
  keka: {
    logo: kekaLogo,
    largeLogo: kekaLogoLarge,
  },
  kenjo: {
    logo: kenjoLogo,
    largeLogo: kenjoLogoLarge,
  },
  keystone_isolved: {
    logo: keystoneLogo,
    largeLogo: keystoneLogoLarge,
  },
  miter: {
    logo: miterLogo,
    largeLogo: miterLogoLarge,
  },
  mp: {
    logo: mpLogo,
    largeLogo: mpLogoLarge,
  },
  mpay: {
    logo: mpayLogo,
    largeLogo: mpayLogoLarge,
  },
  multiplier: {
    logo: multiplierLogo,
    largeLogo: multiplierLogoLarge,
  },
  namely: {
    logo: namelyLogo,
    largeLogo: namelyLogoLarge,
  },
  natural_hr: {
    logo: naturalHRLogo,
    largeLogo: naturalHRLogoLarge,
  },
  netchex: {
    logo: netchexLogo,
    largeLogo: netchexLogoLarge,
  },
  nextep: {
    logo: nextepLogo,
    largeLogo: nextepLogoLarge,
  },
  newtek: {
    logo: newtekLogo,
    largeLogo: newtekLogoLarge,
  },
  north_bay_payroll: {
    logo: northbaypayrollLogo,
    largeLogo: northbaypayrollLogoLarge,
  },
  nvision: {
    logo: nvisionLogo,
    largeLogo: nvisionLogoLarge,
  },
  oasis: {
    logo: oasisLogo,
    largeLogo: oasisLogoLarge,
  },
  obsidianhr_isolved: {
    logo: obsidianhrLogo,
    largeLogo: obsidianhrLogoLarge,
  },
  odoo: {
    logo: odooLogo,
    largeLogo: odooLogoLarge,
  },
  omni_hr: {
    logo: OmniHRLogo,
    largeLogo: OmniHRLogoLarge,
  },
  onepointhcm: {
    logo: onepointhcmLogo,
    largeLogo: onepointhcmLogoLarge,
  },
  onesource_payroll: {
    logo: onesourcepayrollLogo,
    largeLogo: onesourcepayrollLogoLarge,
  },
  onpay: {
    logo: onpayLogo,
    largeLogo: onpayLogoLarge,
  },
  opolis: {
    logo: opolisLogo,
    largeLogo: opolisLogoLarge,
  },
  oracle_peoplesoft: {
    logo: oraclePeopleSoftLogo,
    largeLogo: oraclePeopleSoftLogoLarge,
  },
  oracle_fusion: {
    logo: oraclefusionLogo,
    largeLogo: oraclefusionLogoLarge,
  },
  oyster_hr: {
    logo: oysterHrLogo,
    largeLogo: OysterHrLogoLarge,
  },
  panther: {
    logo: pantherLogo,
    largeLogo: pantherLogoLarge,
  },
  paragon_payroll_isolved: {
    logo: paragonpayrollLogo,
    largeLogo: paragonpayrollLogoLarge,
  },
  patriot: {
    logo: patriotLogo,
    largeLogo: patriotLogoLarge,
  },
  pay_usa_inc: {
    logo: payUSALogo,
    largeLogo: payUSALogoLarge,
  },
  paychex_flex: {
    logo: paychexFlexLogo,
    largeLogo: paychexFlexLogoLarge,
  },
  paycom: {
    logo: paycomLogo,
    largeLogo: paycomLogoLarge,
  },
  paycor: {
    logo: paycorLogo,
    largeLogo: paycorLogoLarge,
  },
  payday_workforce_solutions: {
    logo: paydayWorkforceSolutionsLogo,
    largeLogo: paydayWorkforceSolutionsLogoLarge,
  },
  payentry: {
    logo: payentryLogo,
    largeLogo: payentryLogoLarge,
  },
  payfit: {
    logo: payfitLogo,
    largeLogo: payfitLogoLarge,
  },
  payfluence: {
    logo: payfluenceLogo,
    largeLogo: payfluenceLogoLarge,
  },
  paylocity: {
    logo: paylocityLogo,
    largeLogo: paylocityLogoLarge,
  },
  paynw: {
    logo: paynwLogo,
    largeLogo: paynwLogoLarge,
  },
  paypro_hcs_isolved: {
    logo: payproHCSLogo,
    largeLogo: payproHCSLogoLarge,
  },
  payroll_for_construction: {
    logo: payroll4constructionLogo,
    largeLogo: payroll4constructionLogoLarge,
  },
  payroll_network: {
    logo: payrollNetworkLogo,
    largeLogo: payrollNetworkLogoLarge,
  },
  payroll_office_of_america: {
    logo: payrollofficeofamericaLogo,
    largeLogo: payrollofficeofamericaLogoLarge,
  },
  payrollontime: {
    logo: payrollontimeLogo,
    largeLogo: payrollontimeLogoLarge,
  },
  payroll_plus_hcm_isolved: {
    logo: payrollPlusHCMLogo,
    largeLogo: payrollPlusHCMLogoLarge,
  },
  payroll_plus_hcm_evolution: {
    logo: payrollPlusHCMLogo,
    largeLogo: payrollPlusHCMLogoLarge,
  },
  payroll_resource_group: {
    logo: PRGLogo,
    largeLogo: PRGLogoLarge,
  },
  payroll_solutions: {
    logo: payrollsolutionsLogo,
    largeLogo: payrollsolutionsLogoLarge,
  },
  paytime: {
    logo: paytimeLogo,
    largeLogo: paytimeLogoLarge,
  },
  pcs_hcm: {
    logo: pcsHCMLogo,
    largeLogo: pcsHCMLogoLarge,
  },
  penta: {
    logo: pentaLogo,
    largeLogo: pentaLogoLarge,
  },
  peoplecore: {
    logo: peoplecoreLogo,
    largeLogo: peoplecoreLogoLarge,
  },
  peopleforce: {
    logo: peopleforceLogo,
    largeLogo: peopleforceLogoLarge,
  },
  peoplehum: {
    logo: peoplehumLogo,
    largeLogo: peoplehumLogoLarge,
  },
  people_lease: {
    logo: peopleleaseLogo,
    largeLogo: peopleleaseLogoLarge,
  },
  personio: {
    logo: personioLogo,
    largeLogo: personioLogoLarge,
  },
  platinum_group: {
    logo: platinumGroupLogo,
    largeLogo: platinumGroupLogoLarge,
  },
  premiernow: {
    logo: premiernowLogo,
    largeLogo: premiernowLogoLarge,
  },
  prestige_peo: {
    logo: prestigepeoLogo,
    largeLogo: prestigepeoLogoLarge,
  },
  primepay: {
    logo: primepayLogo,
    largeLogo: primepayLogoLarge,
  },
  prism_hr: {
    logo: prismHRLogo,
    largeLogo: prismHRLogoLarge,
  },
  proliant: {
    logo: proliantLogo,
    largeLogo: proliantLogoLarge,
  },
  propel_hr: {
    logo: propelHrLogo,
    largeLogo: propelHrLogoLarge,
  },
  prosoftware: {
    logo: prosoftwareLogo,
    largeLogo: prosoftwareLogoLarge,
  },
  proxus_hr: {
    logo: proxusHRLogo,
    largeLogo: proxusHRLogoLarge,
  },
  quality_payroll_benefits: {
    logo: qualitypayrollLogo,
    largeLogo: qualitypayrollLogoLarge,
  },
  questco: {
    logo: questcoLogo,
    largeLogo: questcoLogoLarge,
  },
  quickbooks: {
    logo: quickbooksLogo,
    largeLogo: quickbooksLogoLarge,
  },
  quickhr: {
    logo: quickhrLogo,
    largeLogo: quickhrLogoLarge,
  },
  remote: {
    logo: remoteLogo,
    largeLogo: remoteLogoLarge,
  },
  remotepass: {
    logo: remotepassLogo,
    largeLogo: remotepassLogoLarge,
  },
  resourcing_edge: {
    logo: resourcingEdgeLogo,
    largeLogo: resourcingEdgeLogoLarge,
  },
  restaurant365: {
    logo: restaurant365Logo,
    largeLogo: restaurant365LogoLarge,
  },
  rippling: {
    logo: ripplingLogo,
    largeLogo: ripplingLogoLarge,
  },
  rmi_peo: {
    logo: rmipeoLogo,
    largeLogo: rmipeoLogoLarge,
  },
  adp_run: {
    logo: adpLogo,
    largeLogo: adpLogoLarge,
  },
  runforpartners: {
    logo: runforpartnersLogo,
    largeLogo: runforpartnersLogoLarge,
  },
  sage100: {
    logo: sage100Logo,
    largeLogo: sage100LogoLarge,
  },
  sage_payroll: {
    logo: sagePayrollLogo,
    largeLogo: sagePayrollLogoLarge,
  },
  sapling: {
    logo: saplingLogo,
    largeLogo: saplingLogoLarge,
  },
  sage_hr: {
    logo: sageHrLogo,
    largeLogo: sageHrLogoLarge,
  },
  savant_hcm_evolution: {
    logo: savantHCMEvolutionLogo,
    largeLogo: savantHCMEvolutionLogoLarge,
  },
  sequoia_one: {
    logo: sequoiaLogo,
    largeLogo: sequoiaLogoLarge,
  },
  sesame: {
    logo: sesameLogo,
    largeLogo: sesameLogoLarge,
  },
  sheakley: {
    logo: sheakleyLogo,
    largeLogo: sheakleyLogoLarge,
  },
  simploy: {
    logo: simployLogo,
    largeLogo: simployLogoLarge,
  },
  skuad: {
    logo: skuadLogo,
    largeLogo: skuadLogoLarge,
  },
  solution_services: {
    logo: solutionservicesLogo,
    largeLogo: solutionservicesLogoLarge,
  },
  sourceone: {
    logo: sourceoneLogo,
    largeLogo: sourceoneLogoLarge,
  },
  southeastpersonnel: {
    logo: southeastpersonnelLogo,
    largeLogo: southeastpersonnelLogoLarge,
  },
  square_payroll: {
    logo: squareLogo,
    largeLogo: squareLogoLarge,
  },
  strategic_payroll_solutions_isolved: {
    logo: strategicPayrollSolutionsLogo,
    largeLogo: strategicPayrollSolutionsLogoLarge,
  },
  strongpay: {
    logo: strongpayLogo,
    largeLogo: strongpayLogoLarge,
  },
  successfactors: {
    logo: successfactorsLogo,
    largeLogo: successfactorsLogoLarge,
  },
  suite_people: {
    logo: suitePeopleLogo,
    largeLogo: suitePeopleLogoLarge,
  },
  sunfish: {
    logo: sunfishLogo,
    largeLogo: sunfishLogoLarge,
  },
  surepayroll: {
    logo: surepayrollLogo,
    largeLogo: surepayrollLogoLarge,
  },
  sync_hr: {
    logo: syncHRLogo,
    largeLogo: syncHRLogoLarge,
  },
  synchronyhr: {
    logo: synchronyhrLogo,
    largeLogo: synchronyhrLogoLarge,
  },
  talenta: {
    logo: talentaLogo,
    largeLogo: talentaLogoLarge,
  },
  tandemhr: {
    logo: tandemHRLogo,
    largeLogo: tandemHRLogoLarge,
  },
  adp_teampay: {
    logo: adpTeampayLogo,
    largeLogo: adpTeampayLogoLarge,
  },
  teamworks: {
    logo: teamworksLogo,
    largeLogo: teamworksLogoLarge,
  },
  the_payroll_company: {
    logo: thePayrollCompanyLogo,
    largeLogo: thePayrollCompanyLogoLarge,
  },
  thread_hcm: {
    logo: threadhcmLogo,
    largeLogo: threadhcmLogoLarge,
  },
  toast_payroll: {
    logo: toastPayrollLogo,
    largeLogo: toastPayrollLogoLarge,
  },
  trakstar: {
    logo: trakstarLogo,
    largeLogo: trakstarLogoLarge,
  },
  trinet: {
    logo: trinetLogo,
    largeLogo: trinetLogoLarge,
  },
  trion: {
    logo: trionLogo,
    largeLogo: trionLogoLarge,
  },
  ukg_ready: {
    logo: ukgreadyLogo,
    largeLogo: ukgreadyLogoLarge,
  },
  ulti_pro: {
    logo: ukgreadyLogo,
    largeLogo: ukgLogoLarge,
  },
  uzio: {
    logo: uzioLogo,
    largeLogo: uzioLogoLarge,
  },
  velocity_global: {
    logo: velocityglobalLogo,
    largeLogo: velocityglobalLogoLarge,
  },
  vensure_hr: {
    logo: vensureHRLogo,
    largeLogo: vensureHRLogoLarge,
  },
  venture_workforce: {
    logo: ventureworkforceLogo,
    largeLogo: ventureworkforceLogoLarge,
  },
  vfficient: {
    logo: vfficientLogo,
    largeLogo: vfficientLogoLarge,
  },
  viewpoint_hr_management_spectrum: {
    logo: viewpointHRManagementSpectrumLogo,
    largeLogo: viewpointLogoLarge,
  },
  viewpoint_hr_management_vista: {
    logo: viewpointHRManagementVistaLogo,
    largeLogo: viewpointLogoLarge,
  },
  viventium: {
    logo: viventiumLogo,
    largeLogo: viventiumLogoLarge,
  },
  wagepoint: {
    logo: wagepointLogo,
    largeLogo: wagepointLogoLarge,
  },
  warp: {
    logo: warpLogo,
    largeLogo: warpLogoLarge,
  },
  wave: {
    logo: waveLogo,
    largeLogo: waveLogoLarge,
  },
  web_hr: {
    logo: webHRLogo,
    largeLogo: webHRLogoLarge,
  },
  whirks: {
    logo: whirksLogo,
    largeLogo: whirksLogoLarge,
  },
  wolke: {
    logo: wolkeLogo,
    largeLogo: wolkeLogoLarge,
  },
  workday: {
    logo: workdayLogo,
    largeLogo: workdayLogoLarge,
  },
  workforce_junction: {
    logo: workforcejunctionLogo,
    largeLogo: workforcejunctionLogoLarge,
  },
  worklio: {
    logo: worklioLogo,
    largeLogo: worklioLogoLarge,
  },
  workplace_hcm: {
    logo: workplacehcmLogo,
    largeLogo: workplacehcmLogoLarge,
  },
  workzoom: {
    logo: workzoomLogo,
    largeLogo: workzoomLogoLarge,
  },
  wurk: {
    logo: wurkLogo,
    largeLogo: wurkLogoLarge,
  },
  xenium_hr: {
    logo: xeniumhrLogo,
    largeLogo: xeniumhrLogoLarge,
  },
  xero: {
    logo: xeroLogo,
    largeLogo: xeroLogoLarge,
  },
  xero_us: {
    logo: xeroLogo,
    largeLogo: xeroLogoLarge,
  },
  zenefits: {
    logo: trinetHRLogo,
    largeLogo: trinetHRLogoLarge,
  },
  zimyo: {
    logo: zimyoLogo,
    largeLogo: zimyoLogoLarge,
  },
  zoho_payroll: {
    logo: zohoLogo,
    largeLogo: zohoLogoLarge,
  },
};

export default payrollProviderToLocalLogosMap;
