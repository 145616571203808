import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import MainContainer from 'components/Container/MainContainer';
import SignInDisclaimer from '../../../../components/Text/SignInDisclaimer';
import InstructionHeader from '../../../../components/Instruction/InstructionHeader';
import { AppLink } from 'components/Link/AppLink';
import { getProviderFormLabel } from '../utils';
const RegisterBambooHRCredential = ({
  isLoading,
  onSubmit,
  handleClick,
  sandboxMode,
  provider
}: RegisterImplementationProps) => <>
    {sandboxMode === 'provider' && <InstructionHeader>
        Sign up for a BambooHR demo account{' '}
        <AppLink href="https://bamboohr.com/signup" target="_blank" rel="noreferrer">
          on their website
        </AppLink>{' '}
        and connect it here.
      </InstructionHeader>}
    <MultiValueForm formLabel={getProviderFormLabel(provider)} onSubmit={onSubmit} fieldNames={['subdomain', 'username', 'password']} render={({
    subdomain,
    username,
    password
  }) => <>
          <MainContainer>
            <Input id="subdomain" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} label="Subdomain (from <subdomain>.bamboohr.com)" autoFocus />
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Email" />
            <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
            <SignInDisclaimer>
              A third-party Admin/Accountant will be added.
            </SignInDisclaimer>
          </MainContainer>
          <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit" text="Connect" />
        </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterBambooHRCredential.tsx" />
    {handleClick && <SwitchImplementationButton onClick={handleClick}>
        Authenticate through BambooHR
      </SwitchImplementationButton>}
  </>;
export default RegisterBambooHRCredential;