import styled, { keyframes } from 'styled-components';
import COLORS from 'constants/colors';
import FONTS from 'constants/fonts';
import RedirectLink from 'components/Link/RedirectLink';
import { ConnectError } from 'pages/Authorize/types';
import { useAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { SmallWarningIcon } from 'components/Icons/SmallWarningIcon';
import { Center, HStack } from '@chakra-ui/react';
const slideIn = keyframes`
  0% {
    top: -100px;
  }
  100% {
    top: 20px;
  }
`;
const NotificationsContainer = styled.div`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: ${FONTS.H5};
  font-weight: 500;
  z-index: 9999;
  border: 1px solid ${COLORS.RED.RED_300}};
  background-color: ${COLORS.RED.RED_50};
  color: ${COLORS.RED.RED_600};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  animation: ${slideIn} 0.3s ease-in-out;
`;
const MessageWrapper = styled.div`
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;
interface NotificationsProps {
  error: ConnectError | null;
  setError: (error: ConnectError | null) => void;
}
const Notifications = ({
  error,
  setError
}: NotificationsProps) => {
  const {
    handleStateRedirect,
    setProviderToRedirect,
    providerToRedirect
  } = useAuthorizeContext() || {};
  const handleRedirect = () => {
    if (!providerToRedirect) return;
    handleStateRedirect?.({
      next: 'MANUAL_SIGN_IN',
      nextContext: {
        redirect: true,
        payrollProviderId: providerToRedirect
      }
    });
    setError(null);
    setProviderToRedirect?.(null);
  };
  if (!error || error.hideToast) return null;
  return <NotificationsContainer data-sentry-element="NotificationsContainer" data-sentry-component="Notifications" data-sentry-source-file="Notifications.tsx">
      <HStack align="flex-start" role="alert" data-sentry-element="HStack" data-sentry-source-file="Notifications.tsx">
        <Center boxSize="20px" data-sentry-element="Center" data-sentry-source-file="Notifications.tsx">
          <SmallWarningIcon data-sentry-element="SmallWarningIcon" data-sentry-source-file="Notifications.tsx" />
        </Center>
        <MessageWrapper data-sentry-element="MessageWrapper" data-sentry-source-file="Notifications.tsx">
          <div>
            {error.message}
            {providerToRedirect && <RedirectLink onClick={handleRedirect}>
                Click here to connect.
              </RedirectLink>}
          </div>
        </MessageWrapper>
      </HStack>
    </NotificationsContainer>;
};
export default Notifications;