import { ButtonHTMLAttributes } from 'react';
import Button from 'components/Button/Button';
import Loading from 'components/Loading/Loading';
import COLORS from 'constants/colors';
import { Box } from '@chakra-ui/react';
import { ConnectArrowIcon } from 'components/Icons/ConnectArrowIcon';
interface LoaderButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  isLoading?: boolean;
  disabled?: boolean;
  text: string;
}
const LoaderButton = ({
  color = COLORS.GRAY.GRAY_900,
  isLoading = false,
  disabled = false,
  onClick,
  text,
  ...props
}: LoaderButtonProps) => <Button color={color} isLoading={isLoading} disabled={disabled || isLoading} onClick={onClick} {...props} data-sentry-element="Button" data-sentry-component="LoaderButton" data-sentry-source-file="LoaderButton.tsx">
    {text}
    <Box ml="2" transition="transform 0.2s" data-sentry-element="Box" data-sentry-source-file="LoaderButton.tsx">
      <ConnectArrowIcon data-sentry-element="ConnectArrowIcon" data-sentry-source-file="LoaderButton.tsx" />
    </Box>

    <Loading color={color} isLoading={isLoading} disabled={disabled} data-sentry-element="Loading" data-sentry-source-file="LoaderButton.tsx" />
  </Button>;
LoaderButton.defaultProps = {
  isLoading: false,
  disabled: false
};
export default LoaderButton;