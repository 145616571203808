import { ImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { PayrollProviderId } from './types';

export const AUTOMATED_AUTH_INSTRUCTIONS: Partial<
  Record<PayrollProviderId, Partial<Record<ImplementationKind, string>>>
> = {
  [PayrollProviderId.BOB]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/26207320426516-Bob-Add-a-Service-User',
  },
  [PayrollProviderId.CERIDIAN_DAYFORCE]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/26375315334676-Ceridian-Dayforce-Add-an-administrator',
  },
  [PayrollProviderId.COUNTERPOINT_HCM]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/26207378503444-isolved-Find-your-Client-ID',
  },
  [PayrollProviderId.HUMAANS]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/29886935685012-Humaans-Request-API-keys',
  },
  [PayrollProviderId.HYBRID]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/26207378503444-isolved-Find-your-Client-ID',
  },
  [PayrollProviderId.INSPERITY]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/24181310316180-Insperity-Request-API-keys',
  },
  [PayrollProviderId.ISOLVED]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/32582935412756-isolved-Request-API-Keys',
  },  
  [PayrollProviderId.NAMELY]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/29762220991636-Namely-Request-API-keys',
  },
  [PayrollProviderId.PAYCHEX_FLEX]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/32317630854932-Paychex-Flex-Request-API-Keys',
  },
  [PayrollProviderId.PAYCOM]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/26207564335252-Paycom-Create-an-API-token',
  },
  [PayrollProviderId.PAYLOCITY]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/26208246251668-Paylocity-Request-API-keys',
  },
  [PayrollProviderId.PERSONIO]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/29887088512916-Personio-Request-API-keys',
  },
  [PayrollProviderId.SAGE_HR]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/29767195771668-Sage-HR-Request-API-keys',
  },
  [PayrollProviderId.SAPLING]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/29762358492820-Sapling-Request-API-keys',
  },
  [PayrollProviderId.TRINET]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/26208546175764-TriNet-Request-API-keys',
  },
  [PayrollProviderId.UKG_READY]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/26233108662036-UKG-Ready-Add-a-Service-User',
  },
  [PayrollProviderId.ULTI_PRO]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/26209058806548-UKG-Pro-Add-a-Service-User',
  },
  [PayrollProviderId.WORKDAY]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/26242318065684-Workday-Add-an-Integration-System-User',
    [ImplementationKind.CREDENTIAL]:
      'https://support.tryfinch.com/hc/en-us/articles/26242318065684-Workday-Add-an-Integration-System-User',
  },
  [PayrollProviderId.ZENEFITS]: {
    [ImplementationKind.API_TOKEN]:
      'https://support.tryfinch.com/hc/en-us/articles/30325142628628-TriNet-HR-Platform-fka-Zenefits-Request-API-keys',
  },
};
