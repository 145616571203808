import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import { getProviderFormLabel } from '../utils';
const SignInPaycomApi = ({
  isLoading,
  onSubmit,
  client,
  handleClick,
  instructionsLink,
  provider
}: RegisterImplementationProps) => {
  const instructions: JSX.Element = <p>
      To connect your Paycom account, you must create an API token for{' '}
      <strong>{client.name}</strong>.
    </p>;
  return <SignInBase formLabel={getProviderFormLabel(provider)} instructions={instructions} instructionsLink={instructionsLink} onSubmit={onSubmit} divider fieldNames={['instructionCheck', 'SID', 'apiToken']} render={({
    instructionCheck,
    SID,
    apiToken
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <Input value={SID.value} onChange={event => SID.onChange(event.target.value)} invalid={SID.invalid} label="SID" id="SID" />
              <Input value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Key" id="apiToken" />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Use a Username and Password instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !SID.value || !apiToken.value} type="submit" text="Connect" />
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInPaycomApi" data-sentry-source-file="SignInPaycomApi.tsx" />;
};
export default SignInPaycomApi;